@import "variables";
@import "mixins";

.travel-payment-table {
    @include tableProperty;
}

.travelpayment-scrollbar {
    height: calc(100vh - 245px);
    margin: 10px;
}

.travelpayment-thead {
    @include tableHeadProperty;
}

.travelpayment-table-data {
    .view-travelplan {
        padding: 5px;
        cursor: pointer;
    }
}

.booking-payment-details {
    .head-n-mop {
        padding: 0.5rem 0.6rem;
        background-color: $primaryVeryLight;

        .row-heading {
            color: $blackColor;
            font-size: 0.9rem;
            font-weight: bold;
        }

        .booking-mop {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .label-head {
                font-size: 0.85rem;
                font-weight: 500;
                margin-right: 0.5rem;
            }
        }
    }

    .entry-details {
        padding: 0.5rem;

        .left-part {
            border-right: 0.15rem solid $primaryVeryLight;
        }

        .row-label-n-value {
            margin: 0.5rem 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;

            .data-label {
                font-weight: 600;
            }

            .data-value {
                font-weight: bold;
                color: $primaryColor;
            }
        }
    }
}

/* SCROLL BAR CSS with Styling START */
/* width */
.travelpayment-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.travelpayment-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.travelpayment-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.travelpayment-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

// /* SCROLL BAR CSS with Styling END 