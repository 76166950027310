@import "variables";
@import "mixins";

.setting-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px !important;
    width: 100%;
    padding: 0px 15px;

    .setting-card-body {
        text-align: left;
        .card-text {
            color: $color-gray;
            font-weight: 600;
        }
    }
}

.sb-setting-card {
    margin: 10px 0px;
    padding: 5px 15px;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    border-radius: 0rem;
    border: none !important;
    transition: transform 0.2s;
    background: $whiteColor;
    img {
        width: 30px;
        height: 30px;
    }
    &:hover {
        transform: scale(1.01);
        // box-shadow: $card_shadow_hover;
        .setting-img-card {
            box-shadow: $selectInputShadow;
        }
    }
}

.setting-page-header {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: rgba(201, 203, 202, 0.318) 0px 1px 0px 0px;

}

// .input-div {
//     position: relative;

//     .search-icon {
//         position: absolute;
//         right: 8%;
//         top: 32%;
//         cursor: pointer;
//     }
// }

