@import "variables";
@import "mixins";

.user-track-page {
    padding: 2% 4%;
    // background-image: url("../../src/images/Login_Page/sb-bg.svg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
}

.track-user-logo {
    width: 12%;
}

.track-user-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 10px 20px;
    .user-page-title{
        font-weight: 700;
        color: $primaryColor;
        font-size: $fz16;
    }
}

.track-page-header {
    display: flex;
    justify-content: center;
 margin-bottom: 2%;
}
.user-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 84vh;
    // background: #fff;
    background-image: url("../../src/images/Login_Page/sb-bg.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    padding: 5px;
    box-shadow: 0px 3px 6px 4px $lightGray;
}

.user-track-expired {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    line-height: 40px;
}