@import "variables";
@import "mixins";

.beta-tag {
    padding: 10px 20px;
    position: absolute;
    top: 20px;
    left: -8px;   
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    h6 {
        color: $whiteColor;
    }
}

.beta-tag-trail {
    padding: 6px 20px;
    background: $secondaryColor;
    position: absolute;
    top: 12px;
    left: -72px;
    transform: rotate(-45deg);
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    h6 {
        color: $whiteColor;
        font-size: 12px;
        font-weight: 700;
    }
}



.subscription-box{
    background-image: url("../../src/images/Login_Page/sb-bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;   
    width: 100vw;
    height: 100vh;
    overflow: auto;
    position: relative;
    .login-page-body {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
        
        .travelize-salesbee-logo {
            width: 400px;
            margin: 50px auto;
        }
    }
}

.sb-form-section {
    width: 100%;
}

.unAutherized-page{
    .auauthorized-page-body{
        width: 100%;
        height: 100vh;
        margin: auto;
        padding: 1rem;
        text-align: center;
        .metricInfo-logo{
            img{
                width: 20%;
            }
        }

        .sb-Acess-section {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2rem 0;
        
                img {
                    width: 35%;
                }
            }
    }
}



.sb-login-logo-div {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding-bottom: 16px;
    &.create-subscription{
        padding-bottom: 6px;
    }

    .sb-login-logo {
        width: 60%;
        &.metricinfo{
            width: 50%;
        }
    }
}

.sb-login-row {
    background: $whiteColor !important;
    box-shadow: 0px 0px 8px -1.9px $primarySemiLight;
    width: 70%;
    padding: 25px 30px;
    margin: auto;
    &.create-subscription{
        padding: 25px 30px;
    }
}



// .devider {
//     padding: 5px 0px;
//     .v1 {
//         border-left: 2px solid $primarySemiLight;
//         height: 120px;
//     }
// }


.sb-trv-logo {
    width: 65%;
}

.col-left {
    padding: 15px 25px;
    display: flex;
    align-items: center;
}

.col-right {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 2% 1%;
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    width: 100%;
    padding: 5px 10px;
    // height: 100%;
}

.sb-login-bgimg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .lgn-moving-img {
        height: 350px;
    }
}

.sb-signin-text {
    padding-top: 1%;
}

.sb-forgot-text {
    padding-top: 15%;
}

.login-head-text {
    font-size: $fz25;
    font-weight: $fwt600;
    color: $primaryText_Color;
    padding: 5px 0px;
    // margin-bottom: 30px;
}



.form-control {
    font-size: $fz13;
    // border-radius: 4px !important;
    background: $whiteColor !important;
    border: none;
    box-shadow: 0px 0px 6px -1.4px $primarySemiLight !important;
    padding: 6px 15px;
    color: $primaryText_Color;
    line-height: normal;
    height: auto;

    &.login-email,
    &.login-password {
        margin: 20px auto;
        border-radius: 4px !important;
        padding: 10px 15px;
        color: $primaryText_Color;
        background-color: $whiteColor !important;
        box-shadow: 0px 0px 6px -1.9px $primarySemiLight !important;
        // box-shadow: 0px 0px 3px 0.6px $primarySemiLight !important;
        border: none !important;
        position: relative;
    }

    &:focus {
        box-shadow: 0px 0px 6px -0.1px $primarySemiLight !important;
    }

          &.create-subscription-input {
              margin: 20px auto;
              border-radius: 4px !important;
              padding: 10px 15px;
              color: $primaryText_Color;
              background-color: $whiteColor !important;
              box-shadow: 0px 0px 6px -1.9px $primarySemiLight !important;
              border: none !important;
              position: relative;
          }             
}

.contact-input {
    &.sub-inputs{
        .react-select-container-list{
            &.cntry-code{
                min-width: 70px !important;
                margin: 0px 6px 0px 0px !important;
            }
            .react-select-list__control{
                border-radius: 0.25rem 0 0 0.25rem !important;
            }
        }
    }
}

.create-subcription-otp{
    text-align: center;
    .form-field-div{
        margin: auto 20px;
        .add-OTP-inputs {
            border-radius: 5px;
            font-size: 13px;
            margin: 5px 16px;
        }
    }
}

.forgot-pass-text {
    text-decoration: none;
    font-size: $fz13;
    color: #0452c8;
    font-weight: 800;

    &:focus {
        text-decoration: none;
        box-shadow: none;
    }

    &:hover {
        text-decoration: underline;
    }
}

.login-signup-text {
    color: $primaryColor;

    &:hover {
        color: $primaryColor;
    }
}

.btn-login-button {
    background-color: $primaryColor;
    border-radius: 25px;
    font-size: $fz14;
    font-weight: 600;
    letter-spacing: 1px;    
    padding: 5px 10px;
    color: $whiteColor;
    margin-bottom: 0px;
    min-width: 100%;
    border: none;
    text-transform: uppercase;
    &.create-subscription{
        padding: 8px 15px !important;
        width: 100%;
    }

    &:hover {
        color: $whiteColor;
        background-color: $secondaryColor;
    }

    &:focus {
        background-color: $primaryColor;
    }

    &:focus {
        box-shadow: none;
    }
}

.recaptcha-part{
    margin: auto 5px;
    position: relative;
    .original-recaptcha{
        position: relative;        
    }
}
// }

.pass-hide-show {
    float: right;
    position: absolute;
    top: 18%;
    right: 5%;
    font-size: 18px;
    color: $primaryText_Color;
    cursor: pointer;
}

.password-input {
    display: block;
    position: relative;
}


.login-msg {
    font-size: $fz14;

    &.success {
        color: $statusGreen
    }

    &.false {
        color: $dangerColor;
    }
}


.subscription-page-body {
    text-align: center;
    align-items: center;
    background: $mainBody_backgroundImg left top repeat $nav_back_Graycolor;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;

    .salesbeebasiclogo {
        width: 20%;
        margin: 30px auto;
    }
}

.contact-input {
    display: flex;
}

.sub-inputs {
    margin: 15px auto !important;

    .form-control {
        border-radius: 0 0.25rem 0.25rem 0 !important;
        // border-top-left-radius: 0 !important;
        // border-top-right-radius: 0.25rem;
        // border-bottom-left-radius: 0 !important;
        // border-bottom-right-radius: 0.25rem;
        padding: 10px 15px;
    }

    .form-select {
        padding: 10px 15px;
        display: block;
        border: none !important;
        box-shadow: 0px 0px 6px -1.4px $primarySemiLight !important;
        padding: 0.375rem 2.25rem 0.375rem 0.75rem;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: $fz14;
        font-weight: 400;
        line-height: 1.5;
        color: $primaryText_Color;
        background-color: $whiteColor !important;
        border: none;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0 !important;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &.cntry-code {
            width: 35% !important;

            &:focus {
                border: none !important;
                box-shadow: 0px 0px 6px -0.2px $primarySemiLight !important;
            }
        }
    }
}

.btn-submit-button {
    background-color: $primaryColor;
    border: none;
    border-radius: 5px;
    font-size: $fz14;
    padding: 10px 10px;
    color: $whiteColor;
    margin: 10px auto;
    width: 100%;

    &:hover {
        color: $whiteColor;
        background-color: $primaryColor;
        box-shadow: none;
    }
}

.subscription-res-message {
    .alert {
        padding: 0.5rem 0.5rem;
        font-size: $fz14;
        font-weight: $fwt400;
        text-transform: capitalize;
        border: none;
        margin-bottom: 0px;
        opacity: 1;
        animation-name: visibility;
        animation-duration: 10s;
    }

    @keyframes visibility {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
}

@media (max-width: 576px) {    

    .travelize-salesbee-logo {
        width: 60% !important;
        margin: 30px auto;
    }

    .subscription-box {
        width: auto !important;
        display: block;
        align-items: center;
    }

    .salesbeebasiclogo {
        width: 60% !important;
        margin: 30px auto;
    }

}

// css for captcha
.custom-recaptcha {
    /* Add your custom styles here */
    // For example:
    width: 300px;
    margin: 0 auto;

    // iframe {
    //     // height: 60px !important;
    //     // transform: scale(0.9 0) !important;
    // }

    .rc-anchor-normal {
        height: 55px !important;
    }
}

// css for captcha