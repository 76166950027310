@import "variables";
@import "mixins";


.clients-list-thead {
    @include tableHeadProperty;
}

.seleting-menus .clients-count {
    display: contents;
    .client-count-head {
        flex: none;
        margin: 0px 10px;
    }
}

.track-client.view-on-map {
    position: relative;
    // min-height: auto;
    height: calc(100vh - 125px);
    margin: 0px;
    background-color: $whiteColor;
}

.track-client-page-google-map {
    padding: 0px;    
    position: relative;
    .google-map-box{
        height: 50vh;
    }
}

.track-client-page-client-visits {
    padding: 5px 8px !important;
    .card.view-on-map-card {
        margin-bottom: 10px;
        width: auto;
        height: fit-content;
        // height: calc(100vh - 200px);
        margin-right: 5px;
    }
}

.track-client-meet-detail-scroll {
    height: calc(100vh - 185px);
    overflow-y: auto;
}

.card-body.track-client-meeting-address {
    padding: 5px 8px !important;
    text-align: left;
    p {
        font-size: $fz11;
        margin-bottom: 0px !important;
    }
}

.track-client-page-download-btn {
    display: flex;
    justify-content: flex-end;
    margin: 3px;
    padding: 5px;
    position: relative;
    bottom: 0px !important;
    right: 0px !important;
}


.track-client-meeting-detail-name {
    display: flex;
    align-items: center;
}

.meeting-track-client-user-detail h6 {
    font-size: $fz13;
    font-weight: $fwt600;
    margin-bottom: 0px;
    margin-left: 5px;
    
}

.view-on-map .client-track-search {
    display: inline-flex;
    position: sticky;
    top: 0;
    margin-bottom: 10px;
    .form-control.search{
        font-size: $fz14;
    }
    .fa-search {
        position: relative;
        transform: translateY(10px);
        right: 25px;
        color: $lightGray;
    }
}

.mCS_img_loaded.track-clients{
    margin: 8% 0 5%;
}



/* SCROLL BAR CSS with Styling START */
/* width */
.track-client-meet-detail-scroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.track-client-meet-detail-scroll::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.track-client-meet-detail-scroll::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.track-client-meet-detail-scroll:-webkit-scrollbar-thumb:hover {
    background: $very-Dark-Gray;
}

/* SCROLL BAR CSS with Styling END */