@import "variables";
@import "mixins";

.add-edit-details-container {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    position: relative;

    .add-edit-menu-bar {
        width: 16vw;
        height: 100vh;
        background: $whiteColor;
        position: fixed;
        left: 0;
        top: 0;
        box-shadow: 2px 2px 2px $primaryPaleLight !important;
        z-index: 1009;

        .app-logo {
            padding: 0.5rem;
            height: 5.5rem;
            position: sticky;
            top: 0;
            left: 0;

            .logo-part {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5rem;
            }
        }

        .add-edit-details-menu {
            height: 90vh;
            overflow-x: hidden;
            

            .add-edit-menu-item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0.25rem 0.35rem 0.25rem 0.55rem;
                cursor: pointer;
                &.active{
                    background: $sidebarActive;
                }

                .item-num {
                    background-color: $primaryColor;
                    padding: 0.2rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0.5rem;
                    color: $whiteColor;
                }

                .menu-text {
                    font-size: 0.9rem;
                    font-weight: 500;
                }
            }
        }
    }

    .add-edit-details-form-header {
        height: 15vh;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        position: relative;
        padding: 1rem;
        display: flex;
        align-items: center;

        .back-btn-n-page-header {
            position: absolute;
            left: 18vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }
    }

    .addedit-form-body {
        margin: 0.5rem 0.4rem;
        padding: 0.3rem;

        .add-edit-form-part {
            width: calc(100% - 17vw);
            position: relative;
            background: $whiteColor;
            min-height: 80vh;
            height: auto;
            overflow-x: hidden;
            margin-left: auto;
            padding: 0.8rem 0.5rem;
        }
    }

}

@media screen and (max-width: 1025px) {
    .add-edit-menu-bar {
        width: 18vw !important;
    }

    .back-btn-n-page-header {
        left: 20vw !important;
    }

    .add-edit-form-part {
        width: calc(100% - 18vw) !important;
    }
}