@import "mixins";
@import './variables';

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}


body {
    min-height: 100%;
    padding: 0px;
    margin: 0px;
    position: relative;
    font-size: $fz13;
    color: $primaryText_Color;
    background: $paleWhite $mainBody_backgroundImg left top repeat;
    overflow-x: hidden;
}


.page-wrapper {
    margin: 4rem 0.1rem 2.8rem 0rem;
    padding: 0.3rem;

    .main-body {
        @include mainBody;

        &.toggle-shift-left {
            @include toggleShiftLeft;
        }
    }
}

.pageHeader-bar {    
    padding: 0.5rem 0.85rem;
    width: 100%;
    box-shadow: $topBarShadow;

    .pageheader_bar_items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0.65rem;
    }
}

.search-row {
    display: flex;
    align-items: center;
    padding: 5px 5px;
    box-shadow: $topBarShadow;
}

.main_model {
    background-color: $modalBackground;
    padding: 10px;
    z-index: 9999;
}

.page-content {
    @include pageContentProperty;
    // min-height: 86vh;
}

.page-content-div {
    @include pageContentProperty;
    box-shadow: 0px 0px 3px 2px $whiteColor
}

.page-title {
    @include pageTitle;
}

.page-top-bar-dropdowns, .page-top-Filters {
    display: flex;    
    justify-content: flex-end;
    align-items: center;    
}


.boxCenterUsingPosition {
    @include hvCenterUsingPositionAbsolute;
}

.seleting-menus {
    @include selectingMenus;
    z-index: 1;

    .page-top-bar-dropdowns {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &:focus {
            box-shadow: none;
            border: 1px solid $paleGray;
        }

        .search-input {
            display: flex;
            position: relative;

            .search-field {
                min-width: 150px;
                padding: 5px 20px 5px 10px;
            }

            .fa-search {
                position: absolute;
                top: 12px;
                right: 12px;
            }
        }
    }
}

.select {
    @include SelectDropdowns;

    &:focus {
        box-shadow: $selectBoxShadow !important;
    }
}

#metricInfo_search_bar {
    border-radius: 1.5rem;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    margin: 0.35rem auto;
    min-width: 20rem;
}

.input-div {
    position: relative;
    .search-icon {
        position: absolute;
        right: 3%;
        top: 32%;
        cursor: pointer;
        color: $primarySemiLight;
    }
}

.complaint-issue{
    width: 206%;
}

.table> :not(:first-child) {
    border-top: none;
}

thead th::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $borderColor !important;
    z-index: -1;
    // height: 2px;
    /* Set the height of the sticky border-bottom */
    // background-color: $primaryColor;
    /* Add your preferred border color */
    /* Place the border below the content */
}



tbody {

    tr {
        border-bottom: 1px solid $primaryPaleLight !important;

        td {
            background-color: $whiteColor !important;
            padding: 5px 8px !important;
            vertical-align: middle;
            opacity: 1;
            font-size: 14px;
            font-weight: 500 !important;
            color: $tableTd_Color !important;
            overflow-wrap: break-word;
            line-height: 1.462 !important;
            border-bottom: 1px solid $primaryPaleLight !important;
        }
    }

    th {
        padding: 5px !important;
    }

    .table-row-head-Text {
        font-weight: 600;
        color: $tableTd_MainText;
        font-size: 0.875rem;
    }

    .table-row-sub-text {
        color: $subText_Color;
        font-size: 12px;
    }
}

.MuiTableRow-root {
    &.table_row-inside-tabel-cell:last-child {
        td {
            border-bottom: none !important;
        }
    }
}

.bb-none{
    border-bottom: none !important;
}

.b-1 {
    border: 1px solid $primaryPaleLight;
}

.br-1 {
    border-right: 1px solid $primaryPaleLight;
}

.bl-1 {
    border-left: 1px solid $primaryPaleLight;
}

.bb-1 {
    border-bottom: 1px solid $primaryPaleLight;
}

.bt-1 {
    border-top: 1px solid $primaryPaleLight;
}





.row-head-Text {
    font-weight: 600;
    color: $tableTd_MainText;
    font-size: 0.875rem;
}

.row-sub-text {
    color: $subText_Color;
    font-size: 12px;
}

thead tr th:first-child {
    vertical-align: middle;
}


//// MULTIPLE SELECTOR CSS START ///////
.multiSelectContainer li {
    padding: 5px 10px !important;
    color: $blackColor !important;
}

.multiSelectContainer li:hover {
    background-color: $palelightGreen !important;
    color: $blackColor !important;
    // background: $secondaryColor !important;
}

.singleChip {
    color: $whiteColor !important;
}

.select-add-user-model {
    &.zone {
        .searchWrapper {
            display: flex;
            flex-wrap: wrap;
            // flex-wrap: nowrap;
        }
    }

    .searchWrapper {
        padding: 5px;
        border-radius: 5px !important;
        display: flex;
        flex-wrap: wrap;

        .searchBox {
            margin-top: 0px !important;
        }
    }

    .optionContainer {
        border: "1px solid blue";
        border-radius: 0px !important;
        height: 130px;

        li {
            &:hover {
                background-color: $tableRowHover !important;
            }
        }
    }

    .chip {
        background: $primaryColor;
        line-height: 16px !important;
        padding: 2px 5px !important;
        border-radius: 2px !important;
        margin-bottom: 2px !important;
        margin-right: 5px !important;
    }
}


//// MULTIPLE SELECTOR CSS END ///////


.form-select {
    padding: 0.26rem 2.25rem 0.38rem 0.75rem;
    border: 1px solid $tableRowHover;
}

/// DEFAULT STYLE CHANGE FOR SELECT DROPDOWN START ////
.css-1u9des2-indicatorSeparator {
    background-color: $tableRowHover !important;
}

.css-1xc3v61-indicatorContainer {
    color: $modelhfborderColor !important;

    &:hover {
        color: $tableRowHover !important;
    }
}

.css-tj5bde-Svg {
    color: $borderColor !important;
    margin-right: 3px;
}


.react-select-list {
    &__menu {
        margin: 0.125rem auto;
    }

    &__menu-list {
        &--is-visited {
            background-color: $tableRowHover !important;
            color: $blackColor !important;
        }
    }

    &__option {
        &:hover {
            background-color: $tableRowHover !important;
            color: $blackColor !important;
        }

        &--is-focused {
            background-color: $tableRowHover !important;
            color: $blackColor !important;
        }

        &--is-selected {
            background-color: $primaryColor !important;
            color: $whiteColor !important;
        }
    }
}

/// DEFAULT STYLE CHANGE FOR SELECT DROPDOWN START ////

/// TIME PICKER CSS START
/// .css-cysikj:hover
.css-kjd0cg-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton,
.css-cysikj {
    background-color: $primaryColor !important;

    &:hover {
        background-color: $primaryPaleLight !important;
    }
}

.css-1wahiw3-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton,
.css-ag8lru {
    background-color: $primaryColor !important;

    &:hover {
        background-color: $primaryPaleLight !important;
    }
}

.css-zddqwy-MuiClockNumber-root.Mui-selected {
    background-color: $primaryColor !important;
}

.css-d0vs79-MuiClockPointer-root {
    background-color: $primaryColor !important;
}

.css-umzx0k-MuiClock-pin,
.css-12ha4i7 {
    background-color: $secondaryColor !important;
}


.css-7lip4c,
.css-118whkv {
    background-color: $primaryColor !important;
    border-color: $primaryColor !important;
}

.css-1bn53lx {
    border: none !important;
    outline: none !important;
}

/// TIME PICKER CSS END
/// 
.btn-clear-select {
    @include buttonProperty($nav_back_Graycolor, 30px, 35px, 0, 16px, $whiteColor);
    align-items: center;
    text-align: center;
    padding: 3px;

    &:hover {
        background-color: $grayColor;
        box-shadow: none;
    }

    .bi-x-circle-fill {
        position: relative;
        color: $whiteColor;
    }
}

.btn-clear-track {
    @include buttonProperty($blackColor, 30px, auto, 0, 14px, $whiteColor);
    display: flex;
    align-items: center;

    i {
        padding-right: 3px;
    }
}

.btn-search-track {
    @include buttonProperty($grayColor, 30px, auto, 0, 14px, $whiteColor);
    // margin-right: 5px;
    margin: 0px 5px;
    align-items: center;
    padding: 5px 10px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $blackColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        line-height: 0.9;
        padding-left: 5px;
    }
}

.btn-go-back {
    @include buttonProperty($blackColor, 30px, auto, 0, 15px, $whiteColor);
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;

    &:focus {
        box-shadow: none;
    }

    &:hover {
        color: $whiteColor;
    }

    h6 {
        font-size: $fz15;
        line-height: 0.9;
        padding-left: 6px;
    }
}

.download-and-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    padding: 6px;
    position: relative;
    bottom: 0;
    right: 0;

    .data-count-per-page {
        margin-right: 10px;
    }
}

.btn-download-excel {
    @include buttonProperty($primaryColor, auto, auto, 0, 14px, $whiteColor);

    &:focus {
        box-shadow: none;
    }
}

.modal-content {
    border-radius: 6px !important;
    border: none !important;
}

.fa-times {
    cursor: pointer;
    color: $grayColor;

    &:hover {
        color: $blackColor;
    }
}

.btns-save-cancel {
    @include modelFooter;
    &.confirm {
        justify-content: center !important;
    }
}

.btn-save-up {
    @include buttonProperty($primaryColor, auto, auto, 0, 15px, $whiteColor !important);
    // padding: 5px 15px;
    margin-right: 10px;

    p {
        font-size: $fz14;
        font-weight: $fwt400;
        position: relative;
        bottom: 3px;
    }

    &:hover {
        color: $whiteColor;
    }
}

.btn-cancel {
    @include buttonProperty($lightBackgroundTheme, auto, auto, 0, 14px, $whiteColor);
    // padding: 5px 15px;
    margin-right: 10px;

    p {
        font-size: $fz14;
        font-weight: $fwt400;
        position: relative;
        bottom: 3px;
    }

    &:hover {
        background-color: $whiteColor;
        color: $blackColor;
        // border: none;
        box-shadow: 0px 0px 8px -1.9px rgba(0, 0, 0, 0.2);
    }
}

.search_with-icon {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin: auto 5px;

    .dropdown_search {
        height: 32px;
    }

    .fa {
        position: absolute;
        right: 10px;
    }
}

.no-data-found {
    text-align: center;
    align-items: center;
    color: $nav_back_Graycolor;
    font-size: $fz14;
    margin: 10px auto;
}

#vhd-popover {
    border: none !important;
    border-radius: 0.65rem;
    background-color: $whiteColor;

    .popover-body {
        background-color: $primaryVeryLight;
        border-radius: 0.65rem;
        padding: 0.5rem 0.85rem !important;
        color: $blackColor !important;
        font-weight: 500;
    }
}

#inputFieldErrorMsg {
    color: $dangerColor !important;
    margin-top: 0px !important;
    position: absolute !important;
    font-size: $fz10 !important;
    left: 12px !important;
    top: 35px !important;
}

#inputFieldErrorMsgSuccess {
    color: $primaryColor !important;
    margin-top: 0px !important;
    position: absolute !important;
    font-size: $fz11 !important;
    left: 12px !important;
    top: 38px !important;
}

#kilometerMsgSuccess,
#twolevelclaimMsgSuccess {
    color: $primaryColor !important;
    margin-top: 0px !important;
    position: absolute !important;
    font-size: $fz11 !important;
    left: 12px !important;
    top: 38px !important;
    font-weight: 700;
}

.fa-angle-double-right,
.fa-angle-double-left {
    font-size: $fz10;
    font-weight: $fwt600;
    font-style: normal !important;
}

.error-input-message {
    font-size: $fz10;
    color: $dangerColor;
    position: relative;
    left: 0px;
}

// Hide Input Number Arrows START
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

// Hide Input Number Arrows END

// Select Dropdown CSS START
.react-select-container-list.per-page {
    width: auto !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1uvydh2 {
    padding: 6.5px 14px !important;
    font-size: $fz14 !important;
}

.css-1j9v0by-MuiClock-root,
.MuiPickersPopper-root,
.css-1mtsuo7 {
    z-index: 9999 !important;
}

.add-edit-details-container,
.add-edit-model {
    .searchWrapper {
        min-height: 35px;
        padding: 0.25rem 0.6rem;
        max-height: 80px;
        overflow-x: hidden;
    }

    .model-select {
        margin: 0 !important;
        width: 100% !important;

        .react-select-list__control {
            padding: 3px 10px 8px 8px;
            height: 35px;

            .react-select-list__value-container {
                padding: 2px 3px 0px 2px;
                text-wrap: nowrap;
            }

            .react-select-list__indicator {
                padding: 2px 5px !important;
                position: absolute;
                right: 0px;
                top: 4px;
            }
        }
    }
}


.page-filter-section, .page-top-Filters{
    .react-select-container-list {
        min-width: 140px;
        margin: auto 5px;
        .react-select-list__control {
                position: relative;
                box-shadow: $selectInputShadow;
                border: none;
                min-height: 30px;
                height: 30px;
                font-size: $fz13;
                border-radius: 5px;
        }
    }
}



.react-select-container-list {
    .react-select-list__control {
        position: relative;
        box-shadow: $selectInputShadow;
        border: none;
        min-height: 35px;
        height: 35px;
        font-size: $fz13;
        border-radius: 5px;

        &:hover {
            border: none;
            box-shadow: $selectInputShadow;
        }

        &:focus,
        &:focus-within {
            box-shadow: $selectBoxShadow !important;
        }

        .react-select-list__value-container { 
            text-wrap: nowrap;
        }

        .react-select-list__indicator {
            padding: 2px 5px !important;
        }

        .react-select-list__clear-indicator {
            padding: 2px 5px !important;
            position: absolute;
            right: 20px;
            top: 2px;
        }

        .react-select-list_menu {
            background-color: $paleGray !important;
            max-height: 200px !important;

            &:hover {
                background-color: $OrangeColor !important;
            }
        }

        .react-select-list__input-container {
            margin: 0px;
        }

        .react-select-list__indicator-separator {
            display: none;
        }
    }
}

// Select Dropdown CSS END

#tooltip-googlemap {
    z-index: 9999;
}

.form-text {
    padding: 2px 4px !important;
    font-size: 10px !important;
}

#Errorfails {
    color: $dangerColor !important;
}

/* DatePicker CSS END  */
.select.from-date {
    border-radius: 0px !important;
}

.select.to-date {
    margin-right: 8px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.from-to-date-select {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin: auto 5px;

    .react-datepicker-popper {
        z-index: 9999 !important;
    }

    .select {
        width: 100% !important;
        max-width: 100% !important;
    }

    .input-group-text {
        // padding: 2px 5px;
        height: 30px;
        // margin: 5px 0px;
        font-size: $fz13;
        background-color: darkgrey;
        color: black;
    }
}

.page-header-text-div {
    padding: 2px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.order-details-page-header {
        justify-content: center !important;
    }
}

.page-header-text {
    background: $header_Gradient;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: $fz13;
    font-weight: 700;
    text-transform: uppercase;
}


.modal-right-fade {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40%;
    animation: modal-right-fade-animation 0.3s;
    z-index: 1050;
    padding: 10px 0px 10px 10px;
    display: flex;
    align-items: center;
}

.date-picker-with-icon {
    display: flex;
    // width: 100px !important;
    position: relative;

    &.model-box {
        width: auto !important;

        .fa.calander-icon {
            position: absolute;
            top: 8px;
            right: 15px;
            font-weight: 500;
        }
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.fa.calander-icon {
    position: absolute;
    top: 8px;
    right: 5px;
    font-weight: 500;
}

.react-datepicker-popper[data-placement^=bottom] {
    z-index: 2;
}


.react-select-5-listbox {
    z-index: 9999 !important;

}

.react-datepicker-popper {
    z-index: 9999 !important;
}


.select.single-date,
.select.from-date,
.select.to-date {
    width: 100% !important;
    padding: 2px 5px 2px 5px !important;
}

.select.single-date.react-datepicker-ignore-onclickoutside,
.select.from-date.react-datepicker-ignore-onclickoutside,
.select.to-date.react-datepicker-ignore-onclickoutside {
    box-shadow: none;
    outline: none;
}

.select.from-date,
.select.to-date {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: $primaryColor !important;
}

.page-header-row {
    display: flex;
    align-items: center;
    width: 100%;
}

// REACT DAT-TIME PICKER START
.react-date-time-picker {
    #date-time-picker {
        width: 100%;
        padding: 5px 10px;
    }

    .react-datepicker__input-container {
        .select {
            height: 35px;

            &:focus {
                outline: none;
            }
        }
    }

    .react-datepicker {
        width: max-content;
    }

}

// REACT DAT-TIME PICKER END

/* DatePicker CSS END  */

.react-time-picker {
    margin-left: 12px;
    background-color: white;

}

.react-time-picker__wrapper {
    border: 1px solid $paleGray;
    border-radius: 5px;
}

.react-time-picker__inputGroup {
    padding: 0 5px !important;
}

.react-time-picker__inputGroup__input {

    &:hover,
    &:focus {
        outline: none;
        box-shadow: none;
        border: none;
    }
}

.react-time-picker__clear-button.react-time-picker__button {
    display: none;
    padding: 0px;
}

.form-check-input:checked {
    background-color: $primaryColor !important;
    border-color: $primaryColor !important;

}

.form-check-input:focus {
    box-shadow: none !important;
    border-color: $primaryColor !important;
}

.bulk-temp-and-res-message {
    .upload-res-message {
        &.success {
            color: $successColor;
        }

        &.false {
            color: $dangerColor;
        }
    }
}

.apikey-search-input {
    position: relative;
    width: 100%;
    margin-right: 10px;

    .input-search-api-key {
        font-size: $fz14;
        width: 510px !important;
        height: 32px;
    }

    .fa-search {
        position: absolute;
        right: 30px;
        top: 10px;
        color: $grayColor;
    }
}

.btn-inline-items {
    display: flex;
    position: relative;
    align-items: center;
}

.enable-status {
    h5 {
        font-size: 0.9rem;
    }
}

.enable-radio-client {
    position: relative;
    vertical-align: middle;
    align-items: center;
}

.enable-radio-status {
    margin-left: 10px;
}



.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.text-center {
    text-align: center;
}

.hv-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.align-center{
    align-items: center;
}

.align-baseline{
    align-items: baseline;
}

.wrap-items-gap {
    flex-wrap: wrap;
    gap: 0.8rem;
}

.card-row-nowrap-gap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 0.95rem;
}

.dflex{
    display: flex;
}

.dflex-j-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.wrap-items-gap {
        flex-wrap: wrap;
        gap: 0.5rem;
    }
}

.dflex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.dflex-j-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.wrap-items-gap{
        flex-wrap: wrap;
        gap:0.5rem;
    }
}

.dflex-j-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dflex-j-arround {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.cursor-pointer {
    cursor: pointer;
}

.hamburger-navbar {
    display: none;
}

// ACTIVE INACTIVE CSS
.inactive-status {
    background-color: $inactiveStatusBackground;  
    border: 1px solid $dangerColor;
    padding: 0.25rem;
    border-radius: 1rem;
    .status-text{
        color: $dangerColor;
        font-weight: 600;
        text-transform: capitalize;
    }
}

.active-status {
    background-color: $activeStatusBackground;
    border: 1px solid $successColor;
    padding: 0.25rem;
    border-radius: 1rem;
    .status-text {
        color: $successColor;
        font-weight: 600;
        text-transform: capitalize;
    }
}

.bg-primaryLight{
    background-color: $primaryVeryLight !important;
}

.status-inactive {
    background-color: $dangerColor;
}

.status-active {
    background-color: $successColor;
}

// ACTIVE INACTIVE CSS

// ADD EDIT LINK CSS
.edit-view-link{
    color: $primaryColor;
    .edit-icon-text{
        padding: 0.25rem;
        cursor: pointer;
        i{
            padding-right: 0.20rem;
        }
    }
    .view-icon-text{
        padding: 0.25rem;
        cursor: pointer;
        i {
            padding-right: 0.20rem;
        }
    }
}
// ADD EDIT LINK CSS

/* PAGINATION PART START */

.user-pagination {
    margin-left: 10px !important;
}

.user-pagination ul {
    margin-bottom: 0px !important;
}

.page-link {
    color: $blackColor;
}

.page-link:focus {
    box-shadow: none;
}

.pagination {
    margin: auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: $whiteColor;
}

.pagination>li>a {
    border: 1px solid $primaryColor;
    padding: 8px 15px;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    margin-right: 5px;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: $primaryColor;
    border-color: $primaryColor;
    outline: none;
    color: $whiteColor;
}

.pagination>li>a,
.pagination>li>span {
    color: $primaryColor;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: unset;
}

.pagination>li:first-child>a {
    border-radius: 5px 0px 0px 5px;
}

.pagination>li:last-child>a {
    border-radius: 0px 5px 5px 0px;
}

.pagination>.disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
}

.page-range {
    display: inline-flex;
    margin-left: 20px;
}

/* PAGINATION PART END */


// CAPTCHA STYLE START

.rc-anchor-light.rc-anchor-normal,
.rc-anchor-light.rc-anchor-compact {
    border: 2px solid $primaryColor !important;
}


.rc-anchor-light {
    color: $statusGreen !important;
    border: 2px solid $primaryColor !important;
}

.rc-anchor-light {
    color: $statusGreen !important;
    border: 2px solid $primaryColor !important;
}

.rc-anchor-normal {
    height: 70px !important;
    width: 290px !important;
}

.rc-anchor-logo-portrait {
    margin: 6px 0 0 5px !important;
    width: 58px !important;
    --webkit-user-select: none;
}

// SPINNER STYLE
.color-gradient {
    &.spinner-grow {
        background: $iconBox !important;
    }
}
// SPINNER STYLE


/// DATA VIEW BY 2 COLUMN
.dataview-by-column{
    padding: 0.25rem;
    .data-head{
        font-size: 0.90rem;
        font-weight: bold;
    }
        .data-body {
            font-size: 0.90rem;
            font-weight: 500;
        }
}
/// DATA VIEW BY 2 COLUMN

// CAPTCHA STYLE END

@media screen and (max-width: 476px) {
    .page-wrapper .main-body.toggle-shift-left {
        width: calc(100% - 70px);
        position: relative;
        right: 10px;
        transition: all 0.35s ease;
        transform: translateX(0);
    }

    .topbar-nav .dropdown-toggle {
        position: relative;
        right: 15px;
    }

    .card-dashboard {
        margin-bottom: 10px;

        .card-body {
            display: block;
            width: 100%;
            text-align: center;
        }
    }

    .card-left-img,
    .card-right-data {
        padding-left: 0px !important;
    }

    .card-imag {
        padding-right: 0px !important;
        border-right: none !important;
    }

    .dash-board-left-side,
    .user-activity-right-dash {
        margin-bottom: 10px !important;
    }

    .profile-right-part {
        width: 100% !important;
    }

    .seleting-menus {
        display: flex;

        .select {
            margin: 5px;
            box-shadow: none;
            border: 1px solid $paleGray;
            height: 30px;
            font-size: $fz13;
            max-width: 100% !important;
            border-radius: 0px;
        }

        .btn-clear-select {
            width: 100% !important;
            margin-left: 5px !important;
        }

        .btn-add-user {
            width: 100% !important;
            margin-left: 5px !important;
            align-items: center !important;
            text-align: center !important;
        }
    }

}

@media screen and (max-width: 1280px) {
    body {
        font-size: $fz13;
    }

    tbody td {
        font-size: $fz12 !important;
    }


    .row.view-on-map .col-md-9 {
        padding: 0px !important;
    }
}

.fz6 {
    font-size: $fz6;
}

.fz8 {
    font-size: $fz8;
}

.fz10 {
    font-size: $fz10;
}

.fz11 {
    font-size: $fz11;
}

.fz12 {
    font-size: $fz12;
}

.fz13 {
    font-size: $fz13;
}

.fz14 {
    font-size: $fz14;
}

.fz15 {
    font-size: $fz15;
}

.fz16 {
    font-size: $fz16;
}

.fz18 {
    font-size: $fz18;
}

.fz20 {
    font-size: $fz20;
}

.fz22 {
    font-size: $fz22;
}

.fz24 {
    font-size: $fz24;
}

.fz25 {
    font-size: $fz25;
}

.fz26 {
    font-size: $fz26;
}

.fz28 {
    font-size: $fz28;
}

.fz30 {
    font-size: $fz30;
}

.fz32 {
    font-size: $fz32;
}

.fz34 {
    font-size: $fz34;
}

.fz35 {
    font-size: $fz35;
}

.fz36 {
    font-size: $fz36;
}

.tb-td-sh {
    font-weight: 600;
    // color: $darkgrayColor;
}

.form-label {
    margin-bottom: 0px !important;
    color: $formLabelColor;
    font-size: $fz13;
}

.color-yellow {
    color: $secondaryColor !important;
}

.color-green {
    color: $primaryColor !important;
}

.gryclr {
    color: $darkgrayColor !important;
}

.mrx-pdg-5 {
    margin: 0 0.2em !important;
    padding: 0.5em !important;
}

.div-disabled {
    pointer-events: none !important;
    opacity: 0.4 !important;
    color: $grayColor !important;
}

.btn-disabled {
    pointer-events: none !important;
    opacity: 0.4 !important;
}

.checkbox-row,
.radio-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    // justify-content: ;
}

/* width */
.css-b62m3t-container::-webkit-scrollbar {
    height: 5px;
    width: 4px;
    background-color: $paleWhite;
}

.css-41abqd-MuiTableContainer-root::-webkit-scrollbar {
    height: 5px;
    width: 4px;
    background-color: $paleWhite;
}

/* width */
.css-b62m3t-container::-webkit-scrollbar {
    height: 5px;
    width: 4px;
    background-color: $paleWhite;
}

/* Track */
.css-b62m3t-container::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
.css-b62m3t-container::-webkit-scrollbar-thumb {
    background: $secondaryColor !important;
    border-radius: 0px;
}



/* width */
.multiSelectContainer::-webkit-scrollbar {
    height: 6px;
    width: 4px;
    background-color: $paleWhite;
}

/* Track */
.multiSelectContainer::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
.multiSelectContainer::-webkit-scrollbar-thumb {
    background: $secondaryColor !important;
    border-radius: 0px;
}

/* Track */
.css-b62m3t-container::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
.css-b62m3t-container::-webkit-scrollbar-thumb {
    background: $secondaryColor !important;
    border-radius: 0px;
}

/* width */
.multiSelectContainer::-webkit-scrollbar {
    height: 6px;
    width: 4px;
    background-color: $paleWhite;
}

/* Track */
.multiSelectContainer::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
.multiSelectContainer::-webkit-scrollbar-thumb {
    background: $secondaryColor !important;
    border-radius: 0px;
}


/* SCROLL BAR CSS with Styling START */
/* width */
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background-color: $paleWhite !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $secondaryColor !important;
    border-radius: 0px;

}


/* Handle on hover */
:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */

// css for table
.custom-table-header {
    tr th {
        line-height: 1.462 !important;
        letter-spacing: .5px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        font-size: $fz11;
        color: $tableHead_Color !important;
        padding: 8px;
        z-index: 1;
    }
}

.table {
    tr th {
        line-height: 1.462 !important;
        letter-spacing: .5px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        font-size: $fz11;
        color: $tableHead_Color !important;
        padding: 8px !important;
        z-index: 1;
    }
}

.thead-cell::after {
    content: '';
    position: absolute !important;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $borderColor !important;
    z-index: -1;
}


.custom-table-body tr td {
    font-size: 14px !important;
    padding: 8px !important;
    z-index: 0;
}

.action-class {
    text-align: center !important;
}

.css-1nmdiq5-menu {
    position: relative;
    z-index: 9999 !important;
    width: 200px !important;
}

.css-q34dxg,
.css-1ex1afd-MuiTableCell-root,
.apexcharts-legend-text {
    font-family: 'Nunito Sans', sans-serif !important;
}



.css-1nmdiq5-menu::-webkit-scrollbar {
    height: 5px !important;
    width: 5px !important;
}

/* Track */
.css-1nmdiq5-menu::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
.css-1nmdiq5-menu::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor !important;
    border-radius: 0px !important;
}

.table-main-div {
    box-shadow: none !important;
    border-radius: 0px;
}

.table-align {
    text-align: center;
}

.page-header-div {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .breadcome-link {
        cursor: pointer;
    }

    .breadcome-text {
        position: relative;
    }
}

// pgae header filter section
.page-filter-section {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.85rem;
    width: 100%;
    z-index: 9999;
    background-color: $whiteColor;
    box-shadow: $filterShadow;

    .filter-row-input-boxes{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap : 0.65rem;
    }
    .filter-row-input-boxes-end{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 0.45rem;
    }

    .clients-filter-input-boxs {
        padding: 5px;

        &.filter-box-div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .from-to-date-select {
                margin: auto 5px !important;

                .date-picker-with-icon {
                    width: 100% !important;
                }
            }
        }
    }

    .date-filter {
        min-width: 180px;
    }
}

.upld-n-templt-downld {
    margin: auto 25% auto 5%;
}

.right-fade-modal-dialog {
    width: 100%;
    height: 100%;
    position: relative;
    max-width: 100%;
    margin: 0px;
    box-shadow: 0px 0px 8px -1.9px $veryLightMediumGray;

    .modal-content {
        border: none;
        height: 100%;
        overflow: auto;
        position: inherit;
    }
    .modal-header {
        border: none;
    }
}

.cliam-filter-section {
    display: flex;
    align-items: center;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: $whiteColor;

    .clients-filter-input-boxs {
        padding: 5px;

        &.filter-box-div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .from-to-date-select {
                margin: auto 5px !important;

                .date-picker-with-icon {
                    width: 100% !important;
                }
            }
        }
    }

    .date-filter {
        min-width: 160px;
    }
}

.css-1yhpg23-MuiTableCell-root {
    color: $primaryText_Color !important;
}

.add-edit-basefare-head {
    font-weight: 700;
    color: $primaryColor;
}

.custom-auto-select {
    .dropdown-menu.show {
        font-size: 13px !important;
        padding: 0px;
        z-index: 9999;

        .dropdown-item {
            border-bottom: 1px solid #eee;
            color: $primaryText_Color;

            &:hover {
                background-color: #e0f3e8;
                color: $primaryColor;
            }
        }
    }
}

.referral-company-table {
    th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
    }

    td:first-child {
        position: sticky;
        left: 0;
    }

    th:nth-child(2) {
        position: sticky;
        left: 250px;
        z-index: 2;
    }

    td:nth-child(2) {
        position: sticky;
        left: 250px;
    }
}

.product-pricing-table {
    th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
    }

    td:first-child {
        position: sticky;
        left: 0;
    }

    th:nth-child(2) {
        position: sticky;
        left: 150px;
        z-index: 2;
    }

    td:nth-child(2) {
        position: sticky;
        left: 150px;
    }

    th:nth-child(3) {
        position: sticky;
        left: 300px;
        z-index: 2;
    }

    td:nth-child(3) {
        position: sticky;
        left: 300px;
    }

    th:nth-child(4) {
        position: sticky;
        left: 450px;
        z-index: 2;
    }

    td:nth-child(4) {
        position: sticky;
        left: 450px;
    }
}

// Buy now button
.btn-buynow-btn {
    @include buttonProperty($primaryColor,
        30px,
        auto,
        0,
        14,
        $whiteColor);
    margin: 0px 10px;
    border-radius: 6px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $primaryColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        padding-left: 5px;
    }
}

// Buy now button
// Create List button
.btn-createList-btn {
    @include buttonProperty($secondaryColor,
        30px,
        auto,
        0,
        14,
        $whiteColor);
    margin: 0px 10px;
    position: relative;

    .list-count {
        position: absolute;
        right: -8px;
        top: -8px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primaryColor;
        font-size: 11px;
        color: $whiteColor;
    }

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $secondaryColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        padding-left: 5px;
    }
}

// Create List button

// Create List button
.btn-checkoutList-btn {
    @include buttonProperty($primaryColor,
        30px,
        auto,
        0,
        14,
        $whiteColor);
    margin: 0px 10px;
    position: relative;
    border-radius: 6px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $secondaryColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        padding-left: 5px;
    }
}

// Create List button

// pgae header filter section
// new design css

.filter-icon-text {
    font-size: 0.75rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
        padding-right: 0.35rem;
    }
}

.grid-tile-view {
    font-size: 0.75rem;
}

// Add Buttton With Plus Icon

.fiter-row-clr-btn{
    .filter-clear-button{
        @include addButtonProperty($secondaryColor,
                auto, 25px, 13px,
                $whiteColor, 3px, 15px);
        font-weight: 500;
        
            &:hover {
                color: $whiteColor;
            }
        
            &:focus {
                box-shadow: none;
            }
    }
}

.template-download {
    @include addButtonProperty($secondaryColor,
        auto, 1.5rem, 13px, $whiteColor, 0.3rem, 0.5rem);
    font-weight: 500;


    &:hover {
        color: $whiteColor;
    }

    &:focus {
        box-shadow: none;
    }
}

.bulkupload-template-modal {
    .bulk-upload-button {
        @include addButtonProperty($primaryColor,
            auto, 1.5rem, 13px,
            $whiteColor, 0.25rem, 0.8rem);
        font-weight: 500;

        &:hover {
            color: $whiteColor;
        }

        &:focus {
            box-shadow: none;
        }
    }
}

.add-single-bulk {
    .btn-add-dropdown {
        @include addButtonProperty($primaryColor,
            auto, 25px, 13px,
            $whiteColor, 3px, 12px);
    font-weight: 500;

        &:hover {
            color: $whiteColor;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .btn-custom-primary {
        @include addButtonProperty($primaryColor,
            auto,
            25px,
            14px,
            $whiteColor,
            4px,
            14px);

        &:hover {
            color: $whiteColor;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .btn-custom-secondary {
        @include addButtonProperty($secondaryColor,
            auto, 25px, 14px,
            $whiteColor, 4px, 14px);

        &:hover {
            color: $whiteColor;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .btn-add-save-update {
        @include addButtonProperty($primaryColor,
            auto, 25px, 14px,
            $whiteColor, 4px, 14px);
        font-weight: 500;

        &:hover {
            color: $whiteColor;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .btn-rate-update-false {
        @include addButtonProperty($lightBackgroundTheme,
            auto, 25px, 14px,
            $textHeadColor, 4px, 14px);
        font-weight: 700;
        border: 2px solid $lightBackgroundTheme;

        &:hover {
            color: $textHeadColor;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .btn-rate-update-true {
        @include addButtonProperty($primaryColor,
            auto, 25px, 14px,
            $whiteColor, 4px, 14px);
        font-weight: 700;
        border: 2px solid $primaryColor;

        &:hover {
            color: $whiteColor;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .btn-sb-cancel {
        @include addButtonProperty($secondaryColor,
            auto, 25px, 13px,
            $whiteColor, 4px, 15px);
        font-weight: 500;

        &:hover {
            color: $whiteColor;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .btn-sb-back {
        @include addButtonProperty($secondaryColor,
            auto, 25px, 14px,
            $whiteColor, 4px, 10px);
        font-weight: 700;

        &:hover {
            color: $whiteColor;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .btn-sb-print {
        @include addButtonProperty($primaryColor,
            auto, 25px, 14px,
            $whiteColor, 3px, 14px);
        font-weight: 700;

        &:hover {
            color: $whiteColor;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .single-bulk-upload {
        transform: translate(0px, 32px) !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        border-radius: 5px !important;
        background-color: $blackColor;
        min-width: max-content !important;
        font-size: $fz13 !important;

        li {
            button {
                color: $whiteColor;

                &:hover {
                    color: $secondaryColor;
                }
            }
        }

        .dropdown-item:focus,
        .dropdown-item:active,
        .dropdown-item:hover {
            background-color: transparent !important;
        }
    }
}

.table-input-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

// Add Buttton With Plus Icon



.mCS_img_loaded {
    width: 50% !important;
    margin: 0 auto !important;
}

// Css for floating lables
.form-floating>.form-control,
.form-floating>.form-control:not(:placeholder-shown),
.form-floating:focus-within>.form-control {
    padding: 6px 15px;
    line-height: 25px;
    height: 35px;

    &.form-textarea {
        height: auto !important;
    }    
}

.form-control{
    &.model-input, &.rbt-input {
        padding: 6px 15px;
        line-height: 25px;
        height: 35px;
    }
}

.searchWrapper {
    border: none !important;    
}

.maxHeight-n-scroller {
    .searchWrapper {
        max-height: 100px;
        overflow-x: hidden;
    }
}

.searchWrapper,
.multiSelectContainer ul {
    background-color: $whiteColor;
    box-shadow: $selectInputShadow;
}

.float-input-visible>label,
.float-input-visible:focus-within>label,
.float-input:focus-within>label {
    display: inline-block !important;
    position: absolute;
    top: -3px;
    left: 5px;
    color: $primaryColor;
    background-color: $whiteColor;
    padding: 0px 3px;
    font-size: 14px;
    // font-weight: 600;
    height: 22px;
    border-radius: 4px;
    opacity: .8 !important;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
}

.float-visible-select>label,
.float-visible-select:focus-within>label,
.float-select:focus-within>label {
    display: inline-block !important;
    position: absolute;
    top: -10px;
    left: 5px;
    color: $primaryColor;
    background-color: $whiteColor;
    padding: 0px 3px;
    font-size: 12px;
    height: 18px;
    border-radius: 4px;
    opacity: .8 !important;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out !important;
}

.float-input>label {
    display: inline-block !important;
    position: absolute;
    top: auto;
    left: 0;
    bottom: 10px;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;

}

.float-select>label {
    display: none !important;
}

.custom-auto-select {
    input:focus::placeholder {
        color: transparent !important;
    }
}

.float-visible-select,
.float-select:focus-within {
    .css-1jqq78o-placeholder {
        display: none;
    }
}

.upload-logo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 14px;
    background-image: $uploadBtnColor;
    color: $blackColor;
    text-align: center;
    padding: 4px;

    i {
        font-size: $fz20;
        color: $primaryColor;
    }

    &:hover {
        color: $whiteColor;
        background-image: $uploadBtnHoverColor;
    }
}

.delete-upload-logo {
    position: absolute;
    top: 0px;
    right: 5px;
    font-size: 16px;
    color: $secondaryColor;
    cursor: pointer;
}

// Css for floating lables

// common css for status badge
.status-badge {
    padding: 3px 15px;
    color: $whiteColor;
    border-radius: 5px;
    font-weight: 500;
    font-size: 13px;
    position: relative;
}

.css-41abqd-MuiTableContainer-root,
.MuiTableContainer-root {
    min-height: 75vh !important;
    max-height: 75vh !important;
}

.user-activity-details {
    .css-41abqd-MuiTableContainer-root,
    .MuiTableContainer-root {
        min-height: calc(100vh - 120px) !important;
        max-height: calc(100vh - 120px) !important;
    }
}

.pending-status,
.Pending {
    background-color: $pendStatusBackground;
    color: $pendStatusColor;
    border: 1px solid $pendStatusColor;
}

.follow-status,
.Follow {
    background-color: $followStatusBackground;
    color: $followStatusColor;
    border: 1px solid $followStatusBackground;
}

.common-status {
    background-color: $commonStatusBackground;
    color: $commonStatusColor;
    border: 1px solid $commonStatusBackground;
}

.complete-status,
.Cancelled,
.Completed {
    background-color: $compStatusBackground;
    color: $compStatusColor;
    border: 1px solid $compStatusColor;
}

.Ended {
    background-color: $primaryVeryLight;
    color: $primaryColor;
    border: 1px solid $primaryColor;
}

.Started {
    background-color: $primaryVeryLight;
    color: $primaryColor;
    border: 1px solid $primaryColor;
    animation: startedmeet 5s linear 2s infinite;
}

@keyframes startedmeet {
    0% {
        background-color: $primaryVeryLight;
        color: $primaryColor;
        opacity: 0.3;
        border: 0.2px solid $primaryColor;
    }

    20% {
        background-color: $primaryVeryLight;
        color: $primaryColor;
        opacity: 0.4;
        border: 0.5px solid $primaryColor;
    }

    40% {
        background-color: $primaryVeryLight;
        color: $primaryColor;
        opacity: 0.5;
        border: 0.6px solid $primaryColor;
    }

    60% {
        background-color: $primaryVeryLight;
        color: $primaryColor;
        opacity: 0.6;
        border: 0.8px solid $primaryColor;
    }

    60% {
        background-color: $primaryVeryLight;
        color: $primaryColor;
        opacity: 0.8;
        border: 1px solid $primaryColor;
    }

    100% {
        background-color: $primaryVeryLight;
        color: $primaryColor;
        opacity: 1;
        border: 1px solid $primaryColor;
    }
}

.cancel-status,
.PCancelled,
.Re-schedule,
.Re-Schedule {
    background-color: $cancStatusBackground;
    color: $cancStatusColor;
    border: 1px solid $cancStatusColor;
}

.expire-status,
.Expired {
    background-color: $expirStatusBackground;
    color: $expirStatusColor;
    border: 1px solid $expirStatusColor;
}

.unmapped-status {
    background-color: $unmappedStatusBackgroun;
    color: $unMappedStatusColor;
    border: 1px solid $unmappedStatusBackgroun;
}

.back-btn {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin: 0px 15px 0px 5px;
    background-color: $primaryVeryLight;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;

    &:hover {
        box-shadow: 0px 0px 8px -1.9px $primarySemiLight;
    }

    img {
        width: 72%;
    }
}

// common css for status badge

// MULTISELECT COMPONENT CSS START
.rmsc .dropdown-container:focus-within {
    box-shadow: $selectInputShadow !important;
    border: none;
}

.rmsc {
    position: relative;
    top : -2px;
    .dropdown-container {
        position: relative;
        box-shadow: $selectInputShadow !important;
        outline: 0;
        background-color: var(--rmsc-bg);
        border: none !important;
        border-radius: 5px !important;
        height: 35px;

        .dropdown-heading {
            height: 100% !important;
        }
    }
}

.rmsc .select-item:hover {
    background-color: $tableRowHover !important;
    color: $blackColor !important;
}

.rmsc .select-item.selected {
    background-color: $whiteColor !important;
}

.rmsc {
    .dropdown-heading-dropdown-arrow {
        color: $primaryPaleLight !important;
    }
}

.multiselect-comp {
    &.filter-dropdown {
        margin: 5px;

        .dropdown-container {
            height: 2.35em !important;
            min-height: 2.35em !important;
            max-width: 16em;
            min-width: 16em;
        }

        .dropdown-heading {
            height: 2.35em !important;
            min-height: 2.35em !important;
        }

        .dropdown-content {
            z-index: 3 !important;
        }
    }
}

// MULTISELECT COMPONENT CSS END

.apexcharts-text {

    &.apexcharts-datalabel-label,
    &.apexcharts-datalabel-value {
        font-family: 'Nunito Sans', sans-serif !important;
    }
}

// css for swal alert
div:where(.swal2-icon) {
    width: 65px !important;
    height: 65px !important;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=tip] {
    top: 2.3em !important;
    left: 0.925em !important;
    width: 1em !important;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=long] {
    top: 2em !important;
    right: 0.8em !important;
    width: 1.937em !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: $primaryColor !important;

    &:focus {
        box-shadow: none !important;
    }

    font-size: 13px;
}

// css for swal alert

// Tooltip css
// #tooltip-dashboard {
//     .tooltip-inner {
//         background-color: $toolTipBackground !important;
//         color: $secondaryColor !important;
//     }
// }

.tooltip-arrow::before {
    border-bottom-color: transparent !important;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
}

// Tooltip css

.Toastify__toast-container {
    z-index: 9999999 !important;
}


// Sticky table header
.three-header-sticky th:first-child,
.three-header-sticky td:first-child {
    position: sticky;
    left: 0;
    background-color: white;
    /* To hide the sticky columns' background color */
}

.three-header-sticky th:nth-child(2),
.three-header-sticky td:nth-child(2) {
    position: sticky;
    left: 100px;
    background-color: white;
    /* To hide the sticky columns' background color */
}

.three-header-sticky th:first-child,
.three-header-sticky th:nth-child(2),
.three-header-sticky th:nth-child(3) {
    z-index: 2;
}

.three-header-sticky td:first-child,
.three-header-sticky td:nth-child(2),
.three-header-sticky td:nth-child(3) {
    z-index: 1;
}

.three-header-sticky th:nth-child(3),
.three-header-sticky td:nth-child(3) {
    position: sticky;
    left: 200px;
    background-color: $whiteColor;
    /* To hide the sticky columns' background color */
}


#loading-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    // background-color: rgba(0, 0, 0, 0.3333333333);
    background-color: rgb(0 0 0 / 6%);
    display: none;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#loading-wrapper.show {
    display: block;
}

#loading-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 38px);
    left: 50%;
    color: $whiteColor;
    width: 115px;
    height: 115px;
    margin: -19px 0 0 -58px;
    text-align: center;
    font-size: 20px;
    z-index: 9999;
    // background-color: $lightGreen;
    border-radius: 50%;
}

#loading-text img {
    width: 90%;
}

#loading-content {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 170px;
    height: 170px;
    margin: -85px 0 0 -85px;
    border: 3px solid $dangerColor;
}

#loading-content:after {
    content: "";
    position: absolute;
    border: 3px solid $successColor;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;
}

#loading-content:before {
    content: "";
    position: absolute;
    border: 3px solid $primaryColor;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
}

#loading-content {
    border: 3px solid transparent;
    border-top-color: $secondaryColor;
    border-bottom-color: $secondaryColor;
    border-radius: 50%;
    -webkit-animation: loader 2s linear infinite;
    animation: loader 2s linear infinite;
}

#loading-content:before {
    border: 3px solid transparent;
    border-top-color: $whiteColor;
    border-bottom-color: $whiteColor;
    border-radius: 50%;
    -webkit-animation: loader 3s linear infinite;
    animation: loader 3s linear infinite;
}

#loading-content:after {
    border: 3px solid transparent;
    border-top-color: $primaryColor;
    border-bottom-color: $primaryColor;
    border-radius: 50%;
    -webkit-animation: loader 1.5s linear infinite;
    animation: loader 1.5s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
}

#growing-animation {
    font-size: $fz22 !important;
    color: $primaryColor !important;
    animation: growlowtohigh;
    animation-duration: 4s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


@keyframes growlowtohigh {
    0% {
        // font-size: $fz12 !important;
        color: $secondaryColor !important;
    }

    20% {
        // font-size: $fz13 !important;
        color: $primaryColor !important;
    }

    40% {
        // font-size: $fz14 !important;
        color: $primaryColor !important;
    }

    60% {
        // font-size: $fz15 !important;
        color: $primaryColor !important;
    }

    80% {
        // font-size: $fz16 !important;
        color: $secondaryColor !important;
    }

    100% {
        font-size: $fz24 !important;
        color: $secondaryColor !important;
    }
}

@-webkit-keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        color--webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#content-wrapper {
    color: $whiteColor;
    position: fixed;
    left: 0;
    top: 20px;
    width: 100%;
    height: 100%;
}

.accounts-header {
    margin: 0px 20px;
    font-size: 16px;
    font-weight: 700;
    color: $primaryColor;
}

.color-secondary {
    color: $secondaryColor;
}

.color-warning {
    color: $danderColor;
}

.primary-color {
    color: $primaryColor;
}

.green-Color {
    color: $compStatusColor;
}

.head-text {
    color: $primaryHeaderColor;
    font-weight: 600;
}

.select-time-input {
    width: 100% !important;
    border: none !important;

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        border: none !important;
    }

}

// DEFAULT CHECK BOX AND RADIO , RANGE BUTTON COLORS CSS

input[type=checkbox],
input[type=radio] {
    accent-color: $primaryColor !important;
}

input[type="range"]::-webkit-slider-thumb {
    background: $primaryColor !important;
    box-shadow: none !important;
}

input[type="range"]::-webkit-slider-runnable-track {
    background-color: $primaryVeryLight !important;
}

/** FF*/
input[type="range"]::-moz-range-progress {
    background: $primaryColor !important;
    box-shadow: none !important;
}

input[type="range"]::-moz-range-track {
    background-color: $primaryVeryLight !important;
}

/* IE*/
input[type="range"]::-ms-fill-lower {
    background: $primaryColor !important;
    box-shadow: none !important;
}

input[type="range"]::-ms-fill-upper {
    background-color: $primaryVeryLight !important;
}

// /* All the same stuff for Firefox */
// input[type="range"]::-moz-range-thumb {
//     background: $primaryColor !important;
// }

// /* All the same stuff for IE */
// input[type="range"]::-ms-thumb {
//     background: $primaryColor !important;
// }


.header-filter-btn {
    padding: 0.35rem 0.5rem;
    margin: 0 0.6rem;
    cursor: pointer;
}



.print-file {
    // margin: 50px!important;
    padding: 20px !important;
}

// .Mui-focused {
//     border: none !important;

//     &.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
//         border: none !important;
//     }
// }

.css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.css-igs3ac,
.css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
}


.prime-highlight-value {
    color: $primarySemiLight;
}

.text-format-input {
    background-color: $whiteColor;
    border: none;
}

.table-input {
    box-shadow: none !important;
    border-bottom: 1px solid $commonStatusColor;

    &:focus {
        box-shadow: none !important;
    }
}

.add-edit-kpa-head {
    color: $secondaryColor;
    margin-bottom: 5px;
}

.user-rating i {
    font-size: 20px;
    color: $header_Gradient !important;
}

.setting-img-card {
    width: 50px;
    height: 50px;
    // border-radius: 50%;
    border-radius: 6px;
    background: $iconBackground;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    // filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
    // border: 2px solid $primaryColor;
}

.material-symbols-outlined {
    color: $primaryColor !important;
    font-size: 25px;
}

.menu-symbols {
    font-size: 24px !important;
    color: $textHeadColor !important;
}


.add-bank-upi {
    color: $secondaryColor !important;
    cursor: pointer;
}

.add-bank-upi:hover {
    color: $primaryColor !important;
    text-decoration: underline;
}

// css for loader

// commisiion page table image
.table-data-product-imgages {
    margin-right: 10px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
}

// commisiion page table image


// CSS FOR BUTTONS START

.btn-primiary-color {
    box-shadow: none;
    background-color: $primaryColor;
    color: $whiteColor;
    border-radius: 20px;
    font-size: $fz13;
    padding: 4px 12px;

    &:focus,
    &:hover {
        color: $whiteColor;
        box-shadow: none;
    }
}


// CSS FOR BUTTONS END

// common style for card view page
.card-view-div {
    max-height: 70vh !important;
    min-height: 70vh !important;
    overflow: auto;
}

.card-display-row {
    padding: 10px 0;
    width: 100%;
    min-height: 100% !important;
    display: inline-flex;
    flex-wrap: wrap;
}

.card-container-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    // -webkit-perspective: 1000px;
    // perspective: 1000px;
    // -webkit-transform-style: preserve-3d;
    // transform-style: preserve-3d;
    position: relative;
    padding: 10px;

    .card-container {
        min-width: 100%;
        border-radius: 6px;
        position: relative;
        -webkit-transition: 1.5s ease-in-out;
        transition: 1.5s ease-in-out;
        // transform-style: preserve-3d;
        box-shadow: 0px 0px 6px -2.9px $borderColor;

        .card-header-text {
            color: $primaryColor;
            font-weight: 700;
            font-size: 1rem;
        }
    }
}

// common style for card view page