
@import './variables';

.page-not-found-page{
    text-align: center;
    position: relative;
    top:200px;
    color: $blackColor;
}
.acces-denied-page{
    text-align: center;
    position: relative;
    top: 200px;
    color: $blackColor;
}
.acces-denied-head{
    color: $dangerColor;
}