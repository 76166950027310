@import "variables";
@import "mixins";


.account-settings-page{
    position: relative;
    height: auto;
    padding-bottom: 10px;
    .account-settings-page-body{
    margin: 15px;
    position: relative;    
    }
}

.account_setting_comp{
    position: relative;
    .setting-header{
        h6{
        font-size: $fz14;
        padding-bottom: 5px;
        border-bottom: 1.3px solid $grayColor;
        color: $secondaryColor;
        opacity: 1;
        width: 85%;
        }
    }
    .setting-body{
        font-size: 13px;
        // margin-top: 1rem;
        .sub-heading{
            font-size: 1em;
            margin-top: 0.5rem;
        }
        .setting-options{
            margin-top: 0.6rem;
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap : 1rem;
        }
        .update-button{
            margin: 0.6rem 0;
            .settings-update-btn{
                width: 80%;
                background-color: $primaryColor;
                border: none;
                border-radius: 0px;
                color: $whiteColor;
                font-size: 0.95rem;
                font-weight: 500;
            }
        }
        .note{
            font-weight: 600;            
        }
    }
}

.claim-two-aprroval h6,
.meeting-range-head h6,
.client-mapping-setting-title h6 {
    font-size: $fz14;
    padding-bottom: 5px;
    border-bottom: 1.3px solid $grayColor;
    color: $secondaryColor;
    opacity: 1;
    width: 85%;
}


.allow-meeting-range-select-radio {
    margin-top: 10px;
}

.claim-two-level.approval-enable-radio {
    margin-top: 10px;
}

.approval-enable-radio-status {
    margin: auto 25px 0px 0px;
}

.btn-success.claim-approval-update,
.btn-success.meeting-range-update,
.btn-success.mappingtype-update {
    width: 400px;
    background-color: $primaryColor;
    border: none;
    border-radius: 0px;
}


.col-style{
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
}