@import "variables";
@import "mixins";



.passbook-first-div {
    width: 70%;
}

.passbook-second-div {
    width: 100%;
}

.passbook-third-div {
    width: 100%;
}

.passbook-main-container {
    position: relative;
}

.card-price b {
    display: flex;
    justify-content: space-around;
}

.card-price-graph img {
    width: 50px;
    height: 50px;
}

.passbook-card-body {
    padding: 15px;
    min-height: 85px;
    border-radius: 6px;
    box-shadow: $card_box_shadow;
    display: flex;
    align-items: center;
    width: 100%;

    .passbook-card-row,
    .card-data {
        display: flex;
        align-items: center;
        width: 100%;
    }
}

.passbook-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .card-passbook {
        width: 100%;
        border-radius: 0px !important;
        border: none;
        padding: 0px 20px;
        background-color: transparent;
    }
}


.card-data {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.passbook-line-chart-container {
    padding: 20px;
}

.passbook-line-chart {
    height: auto;
    padding: 20px;
    border-radius: 6px;
    box-shadow: $card_box_shadow;
}
