 @import './variables';

 .order-status-div{
    display: inline-block;
    height: 33.333%;
 }

 .order-status-div1{
    display: flex;
    padding: 0px 10px;
    justify-content: space-evenly;
 }

 .card1{
    width: 15px;
    margin: 0px auto;
    position: relative;
     .circle{
        border-radius: 50%;
        width: 12px;
        height: 12px;
        position: relative;
        cursor: pointer;
        background: $statusGreen;
        margin: 0px auto;
    }
    .progressLine{
        background: $lightBackgroundTheme;
        border-radius: 10px;
        margin: 0px auto;
        .line{
            background: $statusGreen;
            height: 100px;
            width: 4px;
            margin: 0px auto;
            // margin-left: -8px; 
            // margin-bottom: -22px; 
        }
    }
 }


.order-status-inner-div{
    margin-left: 10px;
    width: 150px;
}
.order-confirm-status{
    margin-bottom: 10px;
}
.orderTime{
    width: 130px;
}