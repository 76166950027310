@import "variables";
@import "mixins";


.hierarchy-table {
    @include tableProperty;
}


/* ADD & EDIT CLIENT MODEL START */

.reporting-hierarchy-modal{
    .add-edit-reporting{
        padding: 25px 20px;
        .add-approval-levels-part{
            padding: 10px 5px;
            min-height: 100px;
            max-height: 300px;
            overflow-x: hidden;
        }
    }

}

