@import "variables";
@import "mixins";

.subscribe-input {
    width: auto !important;
    padding: 0.3rem 0.75rem !important;
    background-color: $whiteColor !important;
}

.assign-modules-scrollbar {
    height: auto;
    margin: 0.8rem;
    padding: 1rem;
    position: relative;
    font-size: 15px;    
}

.menu-div-header {
    padding: 0.5rem;

    h2 {
        padding: 0.6rem 0;
        font-size: 1.2em;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        background-color: $primaryVeryLight;
    }

    h5 {
        padding: 0.5rem 0.2rem;
        font-size: 1em;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        border-bottom: 2px solid $primaryPaleLight;
        color: $primaryColor;
    }
}

.module-items-div {
    padding: 0.85rem 0;
}

/* css for toggle check box */
.facility-details {
    .checkbox {
        float: right;
        margin-right: 0px;
    }

    .checkbox1 {
        float: center;
    }

    .checkbox,
    .checkbox1 {
        width: 2.4em;
        height: 1.2em;
        border-radius: 1.5em;
        border: 2.5px solid $LightGray;
        background: $veryLightGray;
        color: $lightBlack;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .checkbox--on {
        flex-direction: row;
        background: $mediumLightGreen;
        border: 2.5px solid $mediumLightGreen;
        color: $successColor;
        flex-direction: row-reverse;
        margin-right: 0 !important;
    }

    .checkbox--on .checkbox__ball {
        margin-right: 0px;
        border-radius: 50%;     
    }

    .checkbox--on .checkbox__ball {
        background: $successColor;
    }
}

#submenu-section-div .checkbox--on {
    background: $lightYellow;
    border: 2.5px solid $lightYellow;
}



#submenu-section-div .checkbox--on .checkbox__ball {
    background: $secondaryColor;
}

.checkbox__ball {
    background: $grayishRed;  
    width: 0.95em;
    height: 0.95em;
    border-radius: 50%;
}

.checkbox__text {
    font-size: 1.8rem;
    margin-left: 5px;
    margin-right: 5px;
}

/* end css for checkboc */

.menu-list-row {   
    box-shadow: $selectInputShadow;
    margin: 2% 0px;
    max-height: 160px;
    min-height: 160px;
}

.css-1nmdiq5-menu {
    position: relative;
    z-index: 9999 !important;
}

.menu-item-column {
    line-height: 12px;
}

.module-head-div {
    padding: 0.8rem 0.4rem;
    margin: 0 0.2rem;
    background-color: $whiteColor;
    display: flex;    
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $tableRowHover;  
}

.menu-name {
    font-size: 0.85em;
    font-weight: 800;
    text-transform: uppercase;
    color: $blackColor !important;    
}

.submenu-row {
    margin-bottom: 2%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.2rem 0.5rem;
    h5{    
    font-size: 1em;
    font-weight: 700;
    }
}

.submenu-name {
    font-size: 0.85em;
    font-weight: 600;
}

.section-sunmenus {
    margin-top: 0.65rem;
    padding: 0.25rem 0.2rem;
    max-height: 160px;
    min-height: 150px;   
}

.section-sunmenus::-webkit-scrollbar {
    width: 3px;
    background-color: $lightGrayishCyanLimeGreen;
}

.section-sunmenus::-webkit-scrollbar-thumb {
    background: $successColor;
}


.scroll {
    overflow-y: scroll;
}

.submenus {
    padding-right: 3px;
}

.matrix-div {  
    margin: 15px 10px;
    padding: 15px;
}

.input-check {    
    top: 0;
    left: 0;
}

.checkmark {
    position: absolute;
    top: 0;

}

/* suscription page css */
.sb-subscription-module {
    padding: 20px 10px;
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;

    .setting-list-div {
        width: 20%;
        padding: 0px 15px;
    }
}

.sb-module-card {
    margin: 0.85rem 0;
    padding: 0.65rem 0.5rem;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    border: none !important;
    transition: transform 0.2s;
    // box-shadow: $darkCyanLimeGreen 0px 5px 20px 0px;
    box-shadow: $card_shadow_hover;
    background: $whiteColor;
    position: relative;
    min-height: 250px;
    z-index: 1 !important;
    cursor: pointer;

    &.sb-disable-card {
        background: $veryLightGray !important;
        cursor: auto !important;
        opacity: 0.8 !important;
    }

    .sb-sbscription-img {
        width: 70px;
        border-radius: 50%;
        background: $iconBox;
        padding: 12px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 90%;
        }
    }

    &:hover {
        transform: scale(1.05);
        // box-shadow: $card_shadow_hover;
        // box-shadow: $darkCyanLimeGreen 0px 5px 20px 0px;
    }
   }

.card-body {
    text-align: center;
}

.sb-module-card img {
    width: 60px;
    height: 60px;
}

.sb-card-title {   
    background: $primaryColor;
    -webkit-background-clip: text;
    color: transparent;  
    font-weight: 600;
    font-size: 16px;
}

.sb-matrix-div {
    padding: 10px 0px;
}

.subscription-back-btn {
    padding: 5px 10px;
    background-color: $primaryColor;
    cursor: pointer;
}

.module-btn-div {
    display: flex;
    justify-content: center;
}

.module-page-header {
    display: flex;
    align-items: center;
    background-color: $whiteColor;
    border-bottom: 1px solid $lightGrayishRed;
    padding: 5px;
    position: sticky;
    /* top: 0; */
}

input[type=checkbox].css-checkbox {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

input[type=checkbox].css-checkbox+label.css-label {
    padding-left: 20px;
    height: 15px;
    display: inline-block;
    line-height: 15px;
    font-size: 15px;
    vertical-align: middle;
    cursor: pointer;
    /* background-image: url("../../public/images/subscription/images-tick-mark.png") !important; */
    background-image: url("../../public/images/subscription/images-check-right.png") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
}

input[type=checkbox].css-checkbox-active+label.css-label {
    background-image: url("../../public/images/subscription/images-check-right.png") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
}


.sb-module-footer {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
}

.sb-menu-count-devider {
    border-right: 3px solid $lightGray;
    margin: 2px 15px;
    height: 20px;
}

.sb-menu-count-icon {
    color: $secondaryColor;
}

/* dropdown */
.subsription-page {
    min-height: 80vh;

    .css-1nmdiq5-menu {
        z-index: 9999 !important;
    }
}

#react-select-4-listbox {
    display: block;
    z-index: 999 !important;
    overflow: visible;
}

.success-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 10px;
}

.page-top-bar-dropdowns .react-select-list__control--menu-is-open {
    z-index: 9999 !important;
    visibility: visible;
}

/* dropdown */
/* suscription page css */