@import "variables";
@import "mixins";


.vhdpage-content-dashboard {
    padding: 0.4rem 0.1rem;
    overflow: hidden;

    .vhd-card-row {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0.85rem;
        width: 100%;
        padding: 0 calc(1.3rem * 0.5);
    }
}


// CARD CSS
.card-vhd-data {
    border: none;
    border-radius: 0.5rem;
    background-color: transparent;
    // background-color: $primaryVeryLight;
    padding: 0.85rem;
    width: 23.33%;
    min-height: 10vh;
    padding: 0.15rem;
    box-shadow: $card_box_shadow;

    &:hover {
        transform: scale(1.03);
    }

    .card-body-data {
        padding: 1.25rem;

        .card-row {
            width: 100%;
            display: flex;
            align-items: center;

            .vhd-card-img {
                padding: 0.25rem;
                flex-basis: 35%;
                margin-right: 0.35rem;
                margin-left: auto;

                span {
                    font-size: 3.5em !important;
                }
            }

            .divider-line {
                height: 8vh;
                padding: 0.25rem;
                border-left: 2px solid $secondaryPaleLight;
            }

            .vhd-card-right-data {
                margin-right: auto;
                margin-left: 0.35rem;
                flex-basis: 60%;

                .vhd-card {
                    text-align: center;

                    .card-head {
                        font-size: 1.05em;
                        font-weight: 600;
                        color: $tableHead_Color;
                    }

                    .card-data {
                        font-size: 1.45em;
                        font-weight: bold;
                        color: $blackColor;
                    }
                }
            }
        }
    }
}


.summary-n-recent-activity {
    margin: 1.35rem auto 0.45rem;

    .card {
        border: none;
        border-radius: 0.5rem;
        box-shadow: $card_box_shadow;
        min-height: 60vh;
        padding: 1rem;
    }

    .vendor-summary-part,
    .recent-activity-part {
        min-height: 75vh;
        max-height: 75vh;

        .card-heading-search-bar {
            .card-heading {
                font-size: 1.2em;
                font-weight: bold;
                color: $primaryColor;
            }
        }
    }

    .summary-table-part {
        .css-41abqd-MuiTableContainer-root,
        .MuiTableContainer-root {
            min-height: 59vh !important;
            max-height: 59vh !important;
            overflow-x: hidden;
        }
    }

    .recent-activity-data {
        padding: 0.25rem 0.35rem 0.25rem 0.2rem;
        min-height: 63vh !important;
        max-height: 63vh !important;
        overflow-x: hidden;
        margin: 0.6rem auto;

        .rcatv-card {
            min-height: 10vh;
            padding: 0.65rem;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            position: relative;
            margin: 0.5rem auto;

            .card-type {
                min-width: 3.5rem;
                min-height: 3.5rem;
                max-width: 3.5rem;
                max-height: 3.5rem;
                padding: 0.45rem;
                border-radius: 50%;
                position: relative;
                margin-left: 0.5rem;

                div {
                    @include hvCenterUsingPositionAbsolute;
                    font-size: 1.3em;
                    font-weight: bold;
                }

                &.po {
                    border: 2px solid rgb(154, 154, 18);
                    background-color: rgb(250, 243, 194);

                    div {
                        color: rgb(154, 154, 18);
                    }
                }

                &.inv {
                    border: 2px solid rgb(198, 1, 99);
                    background-color: rgb(255, 218, 243);

                    div {
                        color: rgb(198, 1, 99);
                    }
                }

                &.pp {
                    border: 2px solid rgb(1, 106, 198);
                    background-color: rgb(218, 240, 255);

                    div {
                        color: rgb(1,
                                106,
                                198);
                    }
                }
            }

            .card-type-data {
                padding: 0.5rem;
                margin-left: 0.5rem;
                flex-wrap: wrap;
                font-size: 1em;
            }
        }
    }
}