@import "variables";
@import "mixins";

.on-off-button {
    margin-right: 15px;
}

.btn:focus {
    box-shadow: none;
}

.operating-hours-table{
    background-color: $whiteColor !important;
}



    .btn-on-button.on {
        background-color: $primaryColor;
        border: 1px solid gray;
        color: $whiteColor;
        font-size: $fz12;
        font-weight: 700;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 5px 10px;
    }
    .btn-on-button.off {
        background-color: $whiteColor;
        border: 1px solid gray;
        color: $primaryHeaderColor;
        font-size: $fz12;
        font-weight: 700;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: none;
        padding: 5px 10px;
    }
    .btn-off-button.on {
        background-color: $whiteColor;
        border: 1px solid gray;
        color: $primaryHeaderColor;
        font-size: $fz12;
        font-weight: 700;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        padding: 5px 10px;
    }
    .btn-off-button.off {
        background-color: rgb(221, 0, 0);
        border: 1px solid gray;
        color: $whiteColor;
        font-size: $fz12;
        font-weight: 700;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        box-shadow: none;
        padding: 5px 10px;
    }


.operating-hours-settings {
    background-color: $whiteColor;
    height: calc(100vh - 40px);
    padding: 8px 10px 8px 10px;
    position: relative;
    .btn-save-btn {
        @include buttonProperty($primaryColor, auto, auto, 0, 15px, $whiteColor);
        float: right;
        padding: 2px 8px !important;
        margin: 10px 5px !important;
    }
}

.day-by-set-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px auto 4px auto;
    .time-set-status p {
        font-size: $fz12;
        padding: 5px;
        margin-bottom: 0px !important;
    }
    .form-check{
        min-height: 1rem !important;
        margin-bottom: 0px !important;

    }
}

div[disabled] {
    pointer-events: none;
    opacity: 0.3;
}

.input-range__track--active{
    background : $primaryColor !important;
}
.input-range__slider{
    background : orangered !important;
    border: 4px solid $primaryColor !important;
}

/* SCROLL BAR CSS with Styling START */
/* width */
.leads-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.leads-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.leads-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.leads-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */