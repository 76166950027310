@import './mixins';
@import './variables';

.products-view-main-cont{
    display: flex;
    width: 100%;
    background-color: $whiteColor;
    padding: 16px;
}
.products-view-first-div{
    width: 35%;
    
}
.products-view-first-div img{
    width: 300px;
    padding: 20px;
    border: 1px solid $palelightGreen;
    display: block;
}

.products-view-second-div{
    width: 65%;
}

.product-name {
    color: $blackColor;
    font-size: 1.35rem;
    font-weight: 600;
    display: contents;
    opacity: 1;
    h4{
        padding: 0;
        line-height: 1.4;
        font-size: inherit;
        font-weight: inherit;
        display: inline-block;
    }
}
.product-price{
    display: flex;
    padding: 10px 0px;
}
.product-discount-price{
    color: $primaryColor;
    font-size: 20px;
    vertical-align: sub;
}
.product-mrp-price{
    font-size: 16px;
    margin-left: 12px;
    vertical-align: middle;
    color: $primaryText_Color;
    text-decoration: line-through;
}
.description-cont{
    /* display: flex; */
    box-shadow: $card_shadow_hover;
    line-height: 2;
    padding: 0px 10px;
}
.description{
    color: $primaryText_Color;
    font-size: 16px;
    font-weight: 600;
}
.description-about-product{
    color: $primaryText_Color;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 0.85rem;
    opacity: 1;
}

.specifications{
    box-shadow: $card_shadow_hover;
    padding: 10px 10px;
    margin-bottom: 8px;
    text-align: justify;
    .sub-header{
        font-size: 1rem;
        color: $blackColor;
        // color: $headerSecondary;
        font-weight: 600;
    }
}

.products-specifications {
    display: grid;
    grid-template-columns: 200px 20px 400px;
    line-height: 2;

    span {
        font-size: 0.8rem;
    }

    b {
        font-size: 0.8rem;
        // color: $headerSecondary;
        color: $blackColor;        
    }
}

.product-view-multiple-image-cont  {
    img{
        width: 100px;
        height: 100px;
    }
}
.product-view-multiple-image-cont{
    display: flex;
    margin-top: 10px;
}
.products-btn{
    display: flex;
    padding: 10px;
    gap: 10px;
    justify-content: flex-start;
    border: none;
}
.products-btn button{
    width: 120px !important;
}
