@import "variables";
@import "mixins";


/* ADD & EDIT CLIENT MODEL START */

.add-input {
    border-radius: 5px;
    font-size: $fz13;
    height: 35px;
    
}

.add-input-area {
    border-radius: 5px;
    font-size: $fz13;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.add-edit-model {
    .modal-content {
        width: auto;
        margin: auto;
    }

    .add-edit {
        padding: 10px 20px;

        // .btns-save-cancel {
            
        //     &.confirm{
        //         justify-content: center !important;
        //     }
            

        //     &:hover {
        //         color: $whiteColor;
        //     }

        // }

        // .btn-save-up {
        //     background-color: $primaryColor;
        //     color: $whiteColor;
        //     border-radius: 5px;
        //     padding: 5px 15px;
        //     height: 30px;

        //     p {
        //         font-size: $fz15;
        //         font-weight: $fwt400;
        //         position: relative;
        //         bottom: 3px;
        //     }

        //     &:hover {
        //         color: $whiteColor;
        //     }
        // }

        // .btn-cancel {
        //     background-color: $grayColor;
        //     color: $whiteColor;
        //     border-radius: 5px;
        //     padding: 5px 15px;
        //     margin-right: 10px;
        //     height: 30px;

        //     p {
        //         font-size: $fz15;
        //         font-weight: $fwt400;
        //         position: relative;
        //         bottom: 3px;
        //     }

        //     &:hover {
        //         color: $whiteColor;
        //     }
        // }

        .confirmation-text-part{
            margin: 2rem auto;
            text-align: center;
            .confirmation-text{
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }
}

.add-edit-heading {
    @include modelHeader;
    .add-edit-head{
        color: $primaryColor;
        font-weight: $fwt600;
    }
}

