@import "variables";
@import "mixins";


.paymentdetails-table {
    @include tableProperty;
}

.paymentdetails-scrollbar {
    height: calc(100vh - 200px);
    margin: 10px;
}

.paymentdetails-thead {
    @include tableHeadProperty;
}

.paymentdetails-table-data{
    .status{
        padding: 5px 6px;
        width: 50%;
        text-align: center;
        border-radius: 5px;
        &.paid{
            background-color: $lightParrotGreen;
            color: $successColor;
        }
        &.cancelled{
            background-color: $lightRed;
            color: $dangerColor;
        }
    } 
    .btn.download-link{
        color: $primaryColor;
    }
}

.btn-add-paymentdetails {
    @include buttonProperty($blackColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $blackColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        margin-left: 2px;
    }
}


.paymentdetails-table-row-data .paymentdetails-edit-link {
    color: $primaryColor;
    font-size: $fz13;
}





/* SCROLL BAR CSS with Styling START */
/* width */
.paymentdetails-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.paymentdetails-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.paymentdetails-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.paymentdetails-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */