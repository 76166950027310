@import "variables";
@import "mixins";

.payment-details-part {
    padding: 1rem;
    min-height: 85vh;
    max-height: 90vh;
    overflow-x: hidden;

    .payment-details-card {
        margin: 1rem 2rem;
        padding: 1rem;
        border-radius: 1rem;
        box-shadow: $card_box_shadow;

        .br-gray {
            border-right: 1.5px solid $iconBackground;
        }

        .bl-gray {
            border-left: 1.5px solid $iconBackground;
        }
    }

    .payment-details-table-part {
        margin: 0.5rem;

        .css-41abqd-MuiTableContainer-root,
        .MuiTableContainer-root {
            min-height: 30vh !important;
            max-height: 30vh !important;
            overflow-x: hidden;
        }

    }
}