@import "variables";
@import "mixins";

.travel-approval-table {
    @include tableProperty;
}

.travelapproval-scrollbar {
    height: calc(100vh - 245px);
    margin: 10px;
}

.travelapproval-thead {
    @include tableHeadProperty;
}

.travelapproval-table-data {

    .edit-travelplan,
    .view-travelplan {
        padding: 5px;
        cursor: pointer;
    }
}

.approver-view-and-approve-part {
    position: relative;
    min-height: calc(100vh - 150px);
    padding: 10px;

    .preview-data {
        position: relative;

    }
}


// TRAVEL TICKET DETAILS START
.travel-ticket-details-page {
    padding: 1rem 2rem;
    min-height: 75vh;
    height: auto;

    .tripId-n-date {
        background-color: $primaryVeryLight;
        padding: 0.4rem 0.6rem;
        color: $blackColor;
        font-size: 0.9rem;

        .ticket-status {
            font-size: 0.95rem;
            margin-right: 1rem;

            .status {
                font-weight: bold;

                &.approved {
                    color: $compStatusColor;
                }

                &.rejected {
                    color: $cancStatusColor;
                }

                &.pending {
                    color: $pendStatusColor;
                }
            }
        }
    }

    .name-team-client-other-details {
        .team-details-n-client-details {
            padding: 0.5rem 0rem;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1.5px solid $primaryVeryLight;

            .planerName-n-teamDetails {
                width: 55%;
                float: left;
                padding-right: 15px;
                border-right: 1.5px solid $primaryVeryLight;
            }

            .client-details {
                width: 45%;
                float: left;
                padding-left: 15px;
            }

            .other-details {
                padding: 0.5rem 0;
            }

            .remarks-for-rejection {
                padding: 0.5rem 0;
            }
        }

        .trp-dtls-label-value {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            font-size: 0.9rem;
            padding: 0.4rem 0.6rem;

            .trp-dtls-label {
                font-weight: 500;
                padding-right: 10px;
                text-wrap: nowrap;
            }

            .trp-dtls-value {
                color: $primaryColor;
                font-weight: 500;
            }
        }
    }

    .advance-cash-aprvd-dtls {
        width: 100%;
        display: flex;
        flex-direction: row;

        .cash-data {
            border-bottom: 1.5px solid $primaryVeryLight;

            .head {
                background-color: $primaryVeryLight;
                padding: 0.4rem 0.6rem;
                font-size: 0.9rem;
                font-weight: bold;
            }

            .body {
                padding: 0.4rem 0.6rem;
                font-size: 0.9rem;
            }
        }

        .particular {
            width: 20%;

            .body {
                font-size: 0.95rem;
                font-weight: 500;
            }

        }

        .applied {
            width: 20%;

            .body {
                color: $primaryHeaderColor;
            }
        }

        .approved {
            width: 20%;

            .body {
                color: $blackColor;
                font-size: 0.95rem;
                font-weight: bold;
            }
        }

        .remarks {
            width: 40%;

            .body {
                font-size: 0.8rem;
            }
        }
    }

    .trip-ticket-details {
        padding-top: 1rem;
        border-top: 0.20rem dotted $primarySemiLight;

        .payment-type {
            padding: 1rem 0.6rem 0.5rem 0.6rem;

            .radio-heading {
                font-size: 0.95rem;
                font-weight: bold;
                margin-bottom: 0.45rem;
            }

            .paymentType-radio {
                font-size: 0.9rem;
            }
        }

        .heading {
            text-align: center;
            font-size: 1.1rem;
            font-weight: bold;
            color: $blackColor;
        }

        .ticket-details-by-travel {
            margin: 0.8rem auto;

            .travel-type-status {
                background-color: $primaryVeryLight;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.6rem 0.6rem;

                .name-n-icon {
                    font-size: 1.2rem;
                    font-weight: bold;
                    color: $primaryColor;
                }

                .trl-type {
                    font-size: 0.8rem;
                    font-weight: bold;
                }

            }

            .ticket-details {
                padding: 0.2rem 0.6rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 0.9rem;
                border-bottom: 1.5px solid $primaryVeryLight;

                .ticket-attachment {
                    margin-left: 1rem;

                    i {
                        font-size: 1.5rem;
                        color: $primaryColor;

                        &:hover {
                            transform: scale(1.5);
                        }
                    }
                }

                .approve-radio-btn {
                    label {
                        font-weight: 800;
                        color: $compStatusColor !important;
                    }

                    .form-check-input {
                        border-color: $compStatusColor !important;
                    }

                    .form-check-input:checked {
                        background-color: $compStatusColor !important;
                        border-color: $compStatusColor !important;
                    }
                }

                .reject-radio-btn {
                    label {
                        font-weight: 800;
                        color: $cancStatusColor !important;
                    }

                    .form-check-input {
                        border-color: $cancStatusColor !important;
                    }

                    .form-check-input:checked {
                        background-color: $cancStatusColor !important;
                        border-color: $cancStatusColor !important;
                    }
                }
            }

            .ticket-remarks {
                padding: 0.3rem 0.6rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 0.9rem;
                border-bottom: 1.5px solid $primaryVeryLight;
            }
        }
    }
}

// TRAVEL TICKET DETAILS END
.payment-details-for-each-mot {
    padding: 0.6rem 0.6rem;

    .accordion-item {

        &:last-of-type,
        &:first-of-type {
            border-radius: 0px;
        }

        background-color: $modalBackground;
        border: none;
    }

    .accordion-header {
        .accordion-button {
            background-color: $primaryVeryLight;
            color: $primaryColor;
            border-radius: 0px;
            padding: 0.5rem 1rem;

            &:not(.collapsed) {
                background-color: $primaryVeryLight;
                color: $primaryColor;
                box-shadow: none;
                border-radius: 0px;
            }

            &:focus {
                box-shadow: none;
                border-radius: 0px;
            }

            .clickfor-paymentOption {
                text-transform: capitalize;
                font-size: 0.9rem;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: nowrap;

                .material-symbols-outlined {
                    font-size: 1.8rem;
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .accordion-body {
        padding: 0 0.5rem 0.5rem 0.5rem;
    }

}

.booking-payment-details {
    .heading {
        padding: 5px 10px;
        background-color: $primaryPaleLight;
        color: $whiteColor;
    }

    .mode-of-payment-selction {
        margin-top: 0.5rem;

        .mop-selections {
            padding: 8px 10px;
            border-bottom: 0.15rem solid $primaryVeryLight;

            .mop-heading {
                font-weight: bold;
            }
        }

        .entering-payment-details {
            .enter-details {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                flex-wrap: nowrap;

                .input-field {
                    margin: 0.5rem 0.25rem;
                }
            }
        }
    }
}

.ticket-upload-submit-button {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 0.15rem solid $primaryVeryLight;
}

// TICKET BOOKING DETAILS START



// TICKET BOOKING DETAILS END

/* SCROLL BAR CSS with Styling START */
/* width */
.travelapproval-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.travelapproval-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.travelapproval-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.travelapproval-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

// /* SCROLL BAR CSS with Styling END 