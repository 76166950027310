@import './mixins';
@import './variables';

.chatboot-upload-body{
    height: calc(100vh - 170px);
}

.upload-pdf-chatbot {
    padding: 30px 40px;    
}
