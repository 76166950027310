@import "variables";

.alert-model {
        z-index: 99999;
        background-color: rgba(69, 69, 69, 0.731);
    .modal-content {
        border-radius: 10px !important;
        padding: 20px !important;
        position: absolute !important;
        top: 50px !important;
        background-color: $inputBox_backgroundColor;
        .alert-heading {
            margin: 10px 10px!important;
            text-align: left;
            h6{
                font-size: $fz15;
                i{
                    padding-right: 10px;
                }
            }

            .success {
                color: $successColor;
            }
            .fails{
                color: $danderColor;
            }
        }

        .alert-yes-no {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 15px;

            .alert-btn {
                margin: auto 10px;
                border-radius: 20px;
                color: $whiteColor;
                background-color: $primaryColor;
                border: none;
                box-shadow: none;
                padding: 8px 20px;
            }
        }
    }
}