@import "variables";
@import "mixins";

.pivottable-table {
    @include tableProperty;
    padding: 5px;
}

.pivottable-scrollbar {
    height: calc(100vh - 140px);
    padding: 15px 10px;   
}

// PIVOT TABLE CSS CHNAGES
.pvtDropdownCurrent{
    width: 160px !important;
    border: 1px solid $primaryColor !important;
}

.pvtDropdownActiveValue{
    background-color: $primaryVeryLight !important;
}

// .pvtOutput{
//     height: 280px !important;
//     overflow: scroll !important;
// }

.pvtDropdownValue{
    text-wrap: pretty !important;
    .pvtDropdownIcon{
        color: $primaryColor !important;
    }
}

.pvtAxisContainer li span.pvtAttr{
    background-color: $primaryVeryLight !important;
    // border: 1px solid $primaryColor !important;
    border: none !important;
    // color: $darkgrayColor !important;
    padding: 3px 5px !important;
    text-transform: capitalize !important;
}

.pvtButton{
    background-color: $primaryVeryLight !important;
    border: 1px solid $primaryColor !important;
    color: $blackColor !important;
}

.pvtCheckContainer p.selected{
    background-color: $primaryVeryLight !important;
    color: $blackColor !important;
}

.pvtDragHandle{
    color: $grayColor !important;
}

.pvtFilterBox input{
    padding: 3px 5px !important;
    border: 1px solid $primaryColor !important;
}

.pvtFilterBox h4{
    font-size: $fz16 !important;
}

.pvtDropdownMenu{
        border: 1px solid $primaryColor !important;
        border-top: 1px solid $primaryVeryLight;
}

table.pvtTable thead tr th,
table.pvtTable tbody tr th{
    background-color: $primaryVeryLight !important;
    border: 1px solid $primaryPaleLight !important;
}

table.pvtTable tbody tr td{
    border: 1px solid $primaryPaleLight !important;
}

.pvtAxisLabel{
    text-transform: uppercase;
    color: $primaryColor !important;
}

.pvtTotalLabel{
    text-transform: uppercase;
    font-size: $fz14 !important;
    background-color: $primaryColor;
    color: $blackColor !important;
    opacity: 1 !important;
}

.pvtTotal, .pvtGrandTotal{
    font-size: $fz14 !important;
    font-weight: $fwt600 !important;
    opacity: 1 !important;
}

.pvtRowLabel, .pvtColLabel{
    color: $blackColor !important;
    text-transform: capitalize !important;
}

.page-top-bar-dropdowns{
    .tables-dropdown{
        &.tableNames {
            width: 250px !important;
        }
    }
}