@import "variables";
@import "mixins";

.bank-details-body {
    position: relative;
    min-height: 60vh;

    .no-data-found-part {
        padding: 1rem;
        @include hvCenterUsingPositionAbsolute();
    }
}

.card-details {
    padding: 0.85rem 1rem;
    margin: 0 auto;


    .user-card-details {
        border-radius: 0.5rem;
        box-shadow: $card_box_shadow;
        width: 80%;
        height: 15rem;
        padding: 0.85rem;
        background-color: $activUserBackground;
        margin: 0 auto;

        .pan-card-header {
            text-align: center;
            font-size: 1.2rem;
            font-weight: 600;
            color: $primaryColor;
            padding: 0.25rem;
            margin: 0 20%;
            border: 2px solid $primarySemiLight;
            border-radius: 2rem;
        }

        .photo-n-number {
            padding: 0.25rem 0.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 0.8rem;

            .user-pan-image {
                width: 6.5rem;
                height: 6.5rem;
                background-color: $whiteColor;
                position: relative;
                object-fit: contain;
                border: 1px solid $primaryColor;

                img {
                    padding: 0.25rem;
                    @include hvCenterUsingPositionAbsolute();
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .pan-number-part {
                text-align: center;

                .num-title {
                    font-size: 0.9rem;
                    font-weight: 600;

                }

                .pan-acc-number {
                    h5 {
                        color: $blackColor;
                    }
                }
            }
        }

        .name-on-pancard {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: 1rem;
                padding: 0.25rem;
                font-weight: 500;
            }

            .pan-name {
                text-transform: uppercase;
                color: $primaryColor;

            }
        }

        .bank-details-part {
            position: relative;
        }

    }

    .bank-details-header {
        text-align: center;
        margin-bottom: 0.5rem;

        .accounts-header {
            font-size: 1rem;
            text-transform: uppercase;
        }
    }
}

.bank-acc-number {
    span {
        display: grid;
        grid-template-columns: 90px 20px 150px;
        color: grey;
        text-shadow: white 1px 1px 1px;
        font-size: 0.88rem;
        width: 100%;
        justify-content: flex-start;
        padding-bottom: 0.15rem;
    }
}