@import "variables";
@import "mixins";

.report-container {
    width: 95%;
    height: 100vh;
    padding: 20px;

    position: relative;
    display: flex;
}

.report-container-main-image img {
    width: 100%;
    height: 85vh;
    padding: 10px;
}

.report-container-sub-image {
    display: flex;
    justify-content: space-between;
}

.report-container-sub-inner-image img {
    background-image: radial-gradient($whiteColor, $whiteColor, $lightBackgroundTheme 90%);
    width: 150px;
    height: 150px;
    // border: 1px solid;
    border-radius: 3px;
    padding: 10px;
}


/* CustomStyles.css */

.swiper-button-next {
    right: 10px;
}

.swiper-button-prev {
    left: 0px;
    z-index: 1 !important;
}

.report-container-sub-image .swiper-backface-hidden .swiper-slide {
    margin-left: 10px !important;
    width: 183px !important;
}


.report-container-sub-image .swiper-wrapper {
    display: block !important;
    overflow: auto;
    height: 100vh;
    transform: translate3d(0px, 0px, 0px) !important;
}

.report-container-sub-image .swiper-slide {
    height: auto !important;
    padding: 10px;
}

.report-container-main-image {
    background-image: radial-gradient($whiteColor, $whiteColor, $lightBackgroundTheme 90%);
    border-radius: 10px;
    padding: 10px;
    width: 85%;
    height: 90vh;
}

.report-container-small-image {
    position: relative;
    width: 150px;
    height: 150px;
    transition: transform .2s;
    margin: 0 auto;
}

.report-container-small-image-img {
    background-image: radial-gradient($whiteColor, $whiteColor, $lightBackgroundTheme 70%);
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    height: 100%;
}

.report-container-sub-image {
    display: flex;
    justify-content: space-between;
}

.travel-report-view-icons {
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    color: $primaryColor;
}

.table-data_n_images {
    margin: 5px;
}

.custom-table-header {
    .reprot-table-hading {
        font-size: 20px !important;
        font-family: 'Nunito Sans', sans-serif !important;
    }
}


.travel-report-download-images {
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;

    .attachment-label_n_image {
        width: 100%;
        margin: 10px auto;
        padding: 20px;
        position: relative;

        p {
            font-size: 16px !important;
            color: $primaryColor;
            position: absolute;
            top: 20px;
            left: 20px;
        }

        .claim-attached-imgs {
            position: relative;
            top: 20px;
            left: 20px;

            img {
                width: 90%;
                min-height: 800px;
                max-height: 1300px;
                padding: 15px 10px;

            }
        }
    }
}

.travel-report-client-image {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 50%;
        width: 50%;
    }
}

.tr-tcell-br{
    border-right: 1px solid $primaryPaleLight;
}

.w-300px{
    width: 300px;
}
.w-250px {
    width: 250px;
}
.w-200px {
    width: 200px;
}
.w-180px{
    width: 180px;
}
.w-150px {
    width: 150px;
}
.w-120px{
    width: 120px;
}
.w-100px {
    width: 100px;
}
.w-80px {
    width: 80px;
}

.MuiTableRow-root {
    &.table_row-inside-tabel-cell:last-child {
        td {
            border-bottom: none !important;
        }
    }
}