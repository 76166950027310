@import "variables";
@import "mixins";

.user-activity-n-device-table {
  @include tableProperty;
}

.user-activity-location-device {
  padding: 15px 10px 10px 10px;
  position: relative;
  margin: 0px !important;  
}

.activity-page {  
  &.mapIcon {
    width: 50%;
    height: 50%;    
  }
}

.device-info-part {
  background-color: $whiteColor;
  position: relative;  

  img {
    width: 100%;
    object-fit: cover;
    padding: 0.45rem;    
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.device-info-head {
  border-bottom: 1px solid gray;
}

.device-info-heading {
  font-size: $fz16;
  font-weight: 600;
  color: gray;
  text-align: center;
}

.mobile-device-img-part {
  text-align: center;
  padding: 5px;
  margin-top: 5px;
}

.android-mobile-img {
  width: 30%;
}

.device-info-table {  
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  width: 82%;
  height: 100%;
  padding: 1rem 0.65rem;
  border-radius: 30px;
  min-height: 500px;
  font-size: 15px;
  // overflow-x: hidden; 

  .device-details {
    position: relative;
    height: 100%; 
    top: 7%; 
    .page-heading{
      font-size: 1em;
    }
  }

  .modal-update {
    margin-top: 10px;
    background-color: $lightGreen;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 6px;

    .device-brand {
      font-size: 1.1em; 
      background: $brandGradient;
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      /* For webkit browsers like Chrome and Safari */
    }
    .sub-head{
      font-size: 0.9em !important;
      font-weight: 500;
    }
    p{
      font-size: 0.8em !important;
      font-weight: 500;
    }
  }

  .device-name {
    background-color: $lightGreen;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 6px;
    width: 98%;
    margin: 5px 0px;
    .sub-head {
        font-size: 0.85em !important;
        font-weight: 600;
      }
    
      p {
        font-size: 0.75em !important;
        font-weight: 500;
      }
  }

  .device-storage {
    background-color: $lightGreen;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 6px;
    width: 98%;
    margin: 5px 0px 5px 2%;
    .sub-head {
        font-size: 0.85em !important;
        font-weight: 600;
      }
    
      p {
        font-size: 0.75em !important;
        font-weight: 500;
      }
  }

  .device-table {
    background-color: $lightGreen;
    margin-top: 5px;
    width: 100%;
    padding: 10px 0px !important;

    .device-info-table-data {
      padding: 5px 0px !important;

      tr {
        background-color: $lightGreen;
        border: none !important;

        th,
        td {
          // background-color: $lightGreen !important;
          padding: 7px;
          background-color: $whiteColor !important;
          border: none !important;   
          font-size: 0.85em;
        }
      }
    }
  }
}

.device-info-table-data {
  th,
  td {
    padding: 2px 5px;
  }

  tr:last-child th {
    border-bottom: none !important;
  }

  tr:last-child td {
    border-bottom: none !important;
  }

  tr:first-child {
    border-top: 1px dotted gray;
  }

  tr:last-child {
    border-bottom: 1px dotted gray;
  }
}

.user-activity-thead {
  @include tableHeadProperty;
}

.user-activity-data-scroll {
  height: auto;
}

/* SCROLL BAR CSS with Styling START */
/* width */
.user-activity-data-scroll::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
.user-activity-data-scroll::-webkit-scrollbar-track {
  background: $scrollerBackgroundColor;
}

/* Handle */
.user-activity-data-scroll::-webkit-scrollbar-thumb {
  background: $scrollerThumbColor;
  border-radius: 0px;
}

/* Handle on hover */
.user-activity-data-scroll:-webkit-scrollbar-thumb:hover {
  background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */

// Animtion

.particle {
  left: -127%;
  top: -32px;
  position: absolute;
  animation: shoot 3s ease-out infinite;
  animation-name: shoot, fade;
  border-radius: 50%;

  @for $i from 0 to 400 {
    $t: (
      1 + .01 * random(100)) * 5s;

    &:nth-child(#{ $i + 1 }) {
      $d: random(30) + px;
      width: $d;
      height: $d;
      transform: translate(random(100)*1vw,
          random(100)*1vh
      );
    background: hsl(random(360deg), 80%, 80%);
    animation-duration: $t;
    animation-delay: -.01 * random(100) * $t;
  }
}
}

@keyframes shoot {
  0% {
    transform: translate(50vw, 50vh);
  }
}

@keyframes fade {
  to {
    opacity: 0
  }
}


.circle-container {
  $particleNum: 200;
  $particleColor: hsl(158.18deg 95.38% 33.92%);

  position: absolute;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(hsl(158.18deg 95.38% 33.92%),
        hsl(158.18deg 95.38% 33.92%) 10%,
        hsla(180, 100%, 80%, 0) 56%);

    animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

    @keyframes fade-frames {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.7;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes scale-frames {
      0% {
        transform: scale3d(0.4, 0.4, 1);
      }

      50% {
        transform: scale3d(2.2, 2.2, 1);
      }

      100% {
        transform: scale3d(0.4, 0.4, 1);
      }
    }
  }

  $particleBaseSize: 8;


  @for $i from 1 through $particleNum {
    &:nth-child(#{$i}) {
      $circleSize: random($particleBaseSize);
      width: $circleSize + px;
      height: $circleSize + px;

      $startPositionY: random(10) + 100;
      $framesName: "move-frames-" + $i;
      $moveDuration: 28000 + random(9000) + ms;

      animation-name: #{$framesName};
      animation-duration: $moveDuration;
      animation-delay: random(37000) + ms;

      @keyframes #{$framesName} {
        from {
          transform: translate3d(#{random(100) + vw},
            #{$startPositionY + vh},
            0);
        }

        to {
          transform: translate3d(#{random(100) + vw},
            #{- $startPositionY - random(30) + vh},
            0);
        }
      }

      .circle {
        animation-delay: random(4000) + ms;
      }
    }
  }
}

.activiy-direction-modal {
  .modal-dialog {
    max-width: 65% !important;
  }
}

// Animtion