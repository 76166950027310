@import "variables";
@import "mixins";

.attendance-table {
    @include tableProperty;
}

.attendance-monthly-scroll,
.attendance-daily-scroll {
    height: calc(100vh - 200px);
    margin: 10px;
}

.attendance-list-thead {
    th {
        @include tableHeadProperty;
    }
}

.page-title.atten-fullday {
    text-align: left;
}

.leave-type-request {
    color: $statusOrange !important;
}

.leave-type-approved {
    color: $statusGreen !important;
}

.attendance-monthly-scroll {
    .attendance-list-row-data {
        td {
            vertical-align: top;
            padding-right: 10px !important;
            white-space: pre;
        }

        td:not(:first-child) {
            color: $navLinkColor;
            font-size: $fz13;
        }

    }
}

.attendance-list-name {
    .table-data-attendance-imgages {
        height: 40px;
        margin-right: 10px;
    }
}

.attendance-daily-scroll .attendance-list-row-data td {
    vertical-align: top;
    padding-right: 10px !important;
}

.login-details .login-time-status,
.logout-time-status {
    color: green;
    margin-bottom: 3px;
    margin-top: 0px;
}

.login-time-location,
.logout-time-location {
    p {
        color: green;
        padding: 3px 0px;
        margin-bottom: 0px;
    }

    i {
        // color: $nav_link_Textcolor;
        color: $secondaryColor;
        padding-right: 5px;
    }
}

.atten-monthly {
    thead th {
        position: -webkit-sticky;
    }

    th:nth-child(1),
    th:nth-child(3),
    th:nth-child(2),
    th:nth-child(4) {
        z-index: 4 !important;
    }

    th:nth-child(1),
    td:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        background-color: $whiteColor !important;
        z-index: 3;
        left: 0px;
    }

    th:nth-child(2),
    td:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        background-color: $whiteColor !important;
        z-index: 3;
        left: 165px;
    }

    th:nth-child(3),
    td:nth-child(3) {
        position: -webkit-sticky;
        position: sticky;
        background-color: $whiteColor !important;
        z-index: 3;
        left: 270px;
    }

    th:nth-child(4),
    td:nth-child(4) {
        position: -webkit-sticky;
        position: sticky;
        background-color: $whiteColor !important;
        z-index: 3;
        left: 400px;
    }
}


// ATTENDENCE BREAKUPS MODAL START

.modal-backdrop-attendance {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $mediumGray;
    z-index: 1040;

    .modal-header {
        border-bottom: 1px solid gray !important;
    }
}


.attendance_beakup {
    &.modal-heading {
        font-size: $fz14;
    }
}

@keyframes modal-right-fade-animation {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}


.details-row {
    padding: 10px 5px;
    border-bottom: 1px dotted gray;
}

.contact-footer {
    justify-content: flex-start;
}

// ATTENDENCE BREAKUPS MODAL END



/* SCROLL BAR CSS with Styling START */
/* width */
.attendance-monthly-scroll,
.attendance-daily-scroll {
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;

    }
}

/* Track */
.attendance-monthly-scroll,
.attendance-daily-scroll {
    &::-webkit-scrollbar-track {
        background: $scrollerBackgroundColor;
    }
}

/* Handle */
.attendance-monthly-scroll,
.attendance-daily-scroll {
    &::-webkit-scrollbar-thumb {
        background: $scrollerThumbColor;
        border-radius: 0px;
    }
}

/* Handle on hover */
.attendance-monthly-scroll,
.attendance-daily-scroll {
    &:-webkit-scrollbar-thumb:hover {
        background: rgb(102, 102, 102);
    }
}

.holiday-help-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    h6 {
        padding: 0.5rem 0.35rem;
        font-weight: bold;
    }
        .holiday-abbreviation{
            .note-leave-rejected, 
            .note-leave-approved{
                padding: 0 0.5rem;
            }
        }
}

/* SCROLL BAR CSS with Styling END */