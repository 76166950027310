.payrole-container-header-div{
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
}
.payroll-first-container{
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px;
}
.payroll-first-inner-container{
    display: block;
    padding: 0px 50px;
}
.payroll-first-inner-container-header{
    padding: 10px 0px;
}
.payroll-second-container{
    display: flex;
    align-items: center;
    padding: 10px 10px;
}
.payrole-input-div input{
    width: 180px;
}
.payroll-second-inner-container{
    display: block;
    padding: 0px 15px;
}
.payroll-add-close-btn{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}