@import "mixins";
@import 'variables';

.gl-voucher-table-container{
    .expense-voucher{
        &.gl-wise{
            .sub-table{
                tr:last-child{                    
                    border-bottom: none !important;                    
                }
                tr{
                    td:last-child{
                        border-right: none !important;
                    }
                }
            }
        }
    }
}