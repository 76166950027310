@import "variables";
@import "mixins";

.customForms_table {
    padding: 10px;
    position: relative;
}

.customForms_table-scroll {
    height: calc(100vh - 145px);
    margin: 10px;
}


.custom_form_fields {
    padding-right: 15px;
    border-right: 2px solid $primaryColor;

    .dynamic-inputs {
        .icon-with-fields {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 5px;
            background-color: $primaryPaleLight;
            color: $blackColor;
            border-radius: 5px;

            .field-icon {
                max-width: 25px;
                max-height: 25px;
                margin: 0 10px 0 5px;

                .icon {
                    width: 100%;
                }
            }

            .field-names {
                font-size: $fz20;
            }
        }
    }
}

.form-part-height-scroll {
    padding: 15px 5px;
    height: 420px;
    overflow-x: hidden;

    .dropped-form-fields-part {
        position: relative;
        border: 2px solid $primaryColor;
        border-radius: 5px;
        text-align: center;
        padding: 20px 15px;

        .custom-form-heading {
            color: $blackColor;
            font-size: $fz18;
            margin-bottom: 15px;
            text-transform: uppercase;
        }

        .hr-line-custom-form {
            margin: 10px auto;
            padding: 5px 2px;
            border-bottom: 2px dotted $primaryColor;
        }
    }
}



.dropped-form-fields {
    .form-field-edit-delete {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 8px;
        width: 100%;
        background-color: $modalBackground;

        .radio-button-label,
        .checkbox-button-label {
            text-align: left !important;
            color: $primaryColor;
            width: 100%;
            font-weight: $fwt500;
            font-size: $fz14;
        }

        .fa-edit-delete-icons {
            .fa {
                margin: 2px;
                padding: 2px 3px;
                border-radius: 5px;
                background-color: $primaryPaleLight;
                color: $blackColor;

                &.fa-pencil:hover {
                    background-color: $navLinkColor;
                    color: $whiteColor;
                    text-align: center;
                }

                &.fa-times:hover {
                    background-color: $OrangeRed;
                    color: $whiteColor;
                    text-align: center;
                }
            }
        }
    }
}


.radio-label-n-buttons,
.checkbox-label-n-buttons {
    background-color: $modalBackground;

    .radio-group,
    .checkbox-group {
        padding: 2px 5px;
        text-align: left;
        display: block;
    }

    .radio-group-inline,
    .checkbox-group-inline {
        padding: 2px 5px;
        display: flex;
        text-align: left;
        margin: auto;
    }
}

.field-label-n-buttons {
    background-color: $modalBackground;
    width: 100%;

    .field-button-label {
        display: flex;
        text-align: left !important;
        margin-left: 10px;
        color: $primaryColor;
    }
}


// CREATING CUSTOM FORMS FROM API START

.added-form-fields{
    .created-forms-fields{
        tbody {
                tr {
                    td {
                        padding: 10px 6px !important;
                    }
                }
            }
    }
}
.custom-forms-from-api {
    .custom-forms-table {
        margin: 10px;

        .routes-table-data {
            tbody {
                tr {
                    td {
                        padding: 10px 6px !important;
                    }
                }
            }
        }
    }
}


.change-text-fields-part {

    .options-part {
        position: relative;
        margin: 10px auto;
        padding: 5px;
        max-height: 260px;
        overflow-x: hidden;

        .model-inputs {
            &.dflex-align-center {
                display: flex;
                align-items: center;

                .option-label {
                    margin-right: 5px;
                    width: 100%;
                }

                .option-value {
                    margin-left: 5px;
                }

                .remove-option {
                    margin-left: 5px;
                }
            }
        }
    }

    .add-option-icon {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 110px;

        .plus-icon {
            width: 25px;
            height: 25px;
            text-align: center;
            vertical-align: middle;
            margin: 10px 5px;
            padding: 4px 6px;
            border-radius: 5px;
            color: $whiteColor;
            background-color: $primaryColor;

            i {
                font-size: $fz16;
            }
        }

        .add-option-text {
            color: $primaryColor;
        }
    }
}