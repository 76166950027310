@import './variables';
@import './mixins';

.details-icon {
    cursor: pointer;
    color: $primaryColor !important;
}
.custom-tool-tip .tooltip-inner {
    background-color: $whiteColor !important;
    color: $blackColor !important;
    box-shadow: $selectInputShadow;
}