@import "variables";
@import "mixins";


.basefare-table {
    @include tableProperty;
}

.basefare-scrollbar {
    height: calc(100vh - 200px);
    margin: 10px;
}

.basefare-thead {
    @include tableHeadProperty;
}

.add-policy {
    background-color: $followStatusBackground;
    color: $followStatusColor !important;
    border: 1px solid $followStatusBackground;
}

.btn-add-basefare {
    @include buttonProperty($primaryColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;
    // flex: none;
    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $primaryColor;
    }
    &:focus {
        box-shadow: none;
    }
    h6 {
        font-size: $fz14;
        margin-left: 2px;
    }
}


.basefare-table-row-data .basefare-edit-link {
    color: $primaryColor;
    font-size: $fz13;
}


.MuiTableRow-root{
    &.table_row-inside-tabel-cell:last-child{
        td {
            border-bottom: none !important;
        }
    }
}

/* ADD & EDIT CLIENT MODEL START */

.add-basefare-input{
    border-radius: 5px;
    font-size: $fz13;
    background-color: $inputBackground;
}

.add-edit-basefare-model {
    .modal-content {
        width: 80% !important;
        margin-left: 50px;
    }
    .add-edit-basefare {
        padding: 10px 20px;
        .btns-save-cancel {
            display: inline-flex;
            margin-bottom: 10px;
            align-items: center;
            text-align: center;
            justify-content: center;
            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }
            &:hover {
                color: $whiteColor;
            }
        }
        .btn-save-up {
            background-color: $primaryColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            height: 30px;
            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }
            &:hover {
                color: $whiteColor;
            }
        }
        .btn-cancel {
            background-color: $grayColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            margin-right: 10px;
            height: 30px;
            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }
            &:hover {
                color: $whiteColor;
            }
        }
    }
}

.add-edit-basefare-heading {
    @include modelHeader;
}

// .header-filter-btn {
//     padding: 5px 10px;
//     margin: 0px 15px;
//     cursor: pointer;
// }

.float-label {
    position: absolute;
    left: 7px;
    z-index: 999;
    font-size: 11px;
    font-weight: 700;
    top: -7px;
    background: $whiteColor;
    padding: 2px 5px;
    line-height: 1;
    border-radius: 4px;
}

.basefare-action-btns{
    margin: 5px auto;

}

/* SCROLL BAR CSS with Styling START */
/* width */
.basefare-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.basefare-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.basefare-scrollbar::-webkit-scrollbar-thumb {
    background: $secondaryColor;
    border-radius: 10px;
}

/* Handle on hover */
.basefare-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}
/* SCROLL BAR CSS with Styling END */

// EXPENSE LIMIT PAGE START
.expense-policy-settings{
    .policy-local-outstaion{
        display: flex;        
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .heading{
            color: $primaryColor;
            font-size: 0.9rem;
            margin-left: 1rem;
        }
    }
    .options-policysetto{
        .policy-set-to-label{
            font-size: 0.8rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            color: $primaryColor;
        }
        .checkboxes-row{
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }
    }
    .no-limit-message{
        font-size: 0.8rem;
        color: $secondaryColor;
    }
    .other-settings{
        .settings-label{
            font-size: 0.8rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            color: $primaryColor;
        }
    }
    .policyfor-options{
        .policyfor-label{
            font-size: 0.8rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            color: $primaryColor;
        }
    }
}

// EXPENSE LIMIT PAGE END

