@import './variables';
@import './mixins';

.custom-field-form {
    padding: 20px 5px;
}
.custom-right-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.custom-left-header{
    padding: 10px 10px;
    
        h6 {
            font-size: 14px;
        }
} 
.custom-right-header {
    padding: 10px 10px 0px;
    h6{
        font-size: 14px;
    }
}
.custom-right-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .sp-custom-field-select {
        min-width: 40%;
    }
}
.adding-field-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 5px;

    .vline{
        width: 2px;
        background-color: aliceblue;
        height: 50px;
        margin: 5px 0px;
    }
}
.page-name-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    margin: 0px 5px;
}

.page-name-custom h6 {
    font-size: 14px;
}

.options-part {
    position: relative;
    margin: 10px auto;
    padding: 10px 5px;
    max-height: 200px;
    overflow-x: hidden;
   .model-inputs {
       &.dflex-align-center {
           display: flex;
           align-items: center;

           .option-label {
               margin-right: 5px;
               width: 100%;
           }

           .option-value {
               margin-left: 5px;
           }

           .remove-option {
               margin-left: 5px;
           }
       }
   }
}


   .add-option-icon {
       display: flex;
       align-items: center;
       cursor: pointer;
       width: 110px;

       .plus-icon {
           width: 25px;
           height: 25px;
           text-align: center;
           vertical-align: middle;
           margin: 10px 5px;
           padding: 4px 6px;
           border-radius: 5px;
           color: $whiteColor;
           background-color: $primaryColor;

           i {
               font-size: $fz16;
           }
       }

       .add-option-text {
           color: $primaryColor;
       }
   }