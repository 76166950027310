@import "variables";
@import "mixins";


.add-user-title {
    display: flex;
    justify-content: flex-start;    

    h4 {
        color: $primaryColor;
        font-weight: 500;                
    }
}

.modal-fullscreen-xxl-down {
    max-width: 100% !important;
    margin: 0 !important;
}

.add-bulk-container {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-machine-page-container {    
    position: relative;
    overflow: hidden;
    height: 100vh;

    .bubble-image, .circle-image {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        
        img {
            -webkit-animation: rotate-center 100s linear infinite both;
            animation: rotate-center 100s linear infinite both;
            width: 60%;
        }
    }

    .bubble-image-small, .circle-image-small {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        top: 70px;
        left: 0;       

        img {
            -webkit-animation: rotate-center 100s linear infinite both;
            animation: rotate-center 100s linear infinite both;
            width: 40%;
        }
    }


    .left-image, .right-image {
        position: absolute;
        top: 0;
        left: 0;
    }

    .lines-image {
        position: absolute;
        top: 300px;
        left: 0;

        img {
            width: 40%;
        }
    }

    .add-page-save-cancel {
        padding: 15px 10px 10px 15px;
        border-top: 1px solid $borderColor;
        display: flex;
        justify-content: flex-end;
        border-radius: 0px 0px 6px 6px;
    }
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.bulk-upload-static-image{
    img {
            max-width: 100%;
        }
} 