@import "variables";
@import "mixins";

.travel-desk-page {
    position: relative;
    min-height: calc(100vh - 175px);
    padding: 10px;

    .travel-desk-page-body {
        height: 100%;
        margin: auto;

        .travel-card-row {
            height: 100%;
            display: inline-flex;
            flex-wrap: wrap;
            // justify-content: center;

            .travel-card-col {
                width: 25%;
            }

            .card-traveldesk {
                min-height: 220px;
                margin: 20px;
                padding: 10px;
                box-shadow: $card_shadow_hover;
                transition: transform 0.3s ease-in-out !important;
                cursor: pointer;

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.3s ease-in-out !important;
                }

                .card-body {
                    text-align: center;

                    .card-icon {
                        .material-symbols-outlined {
                            font-size: 60px;
                        }
                    }

                    .card-content {
                        h4 {
                            color: $blackColor;
                            font-weight: 600;
                            padding: 2px 5px;
                        }

                        p {
                            color: $blackColor;
                            font-size: 15px;
                            padding: 5px 15px;
                        }
                    }
                }
            }
        }

        // TRAVEL PLAN
        .travel-plan {
            .select-date-n-travel-by {
                padding: 20px 10px;

                .date-n-travel-selection {
                    border-right: 2px solid $lightGray;

                    .date-picker-part {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        .date-picker-header {
                            color: $primaryColor;
                            font-size: 18px;
                            font-weight: 600;
                            padding-bottom: .5rem;
                        }

                        .date-select-picker {
                            display: flex;
                            align-items: center;

                            .calender-div {
                                padding: 5px;
                                width: 47%;

                                .custom-calender {
                                    border-radius: 6px;
                                    border: none;
                                    box-shadow: $card_box_shadow;

                                    .react-calendar__tile:disabled {
                                        background-color: $whiteColor;
                                    }

                                    .react-calendar__tile--now {
                                        background-color: $lightGray;
                                        border-radius: 3px;
                                    }

                                    .react-calendar__tile--active {
                                        background: $primaryColor;
                                        border-radius: 3px;
                                    }
                                }
                            }
                        }

                        h6 {
                            padding: 5px 0px;
                        }

                        .react-datepicker {
                            // border: 1px solid $primaryPaleLight;
                            border: none;
                            font-size: 1.2rem;

                            .react-datepicker__month-container {
                                // width: 400px;
                                // height: 320px;
                                width: 28vw;
                                height: auto;

                                .react-datepicker__header {
                                    background-color: $primaryVeryLight;
                                    border-bottom: none;
                                    padding: 10px 0px 2px;

                                    .react-datepicker__current-month,
                                    .react-datepicker-time__header,
                                    .react-datepicker-year-header {
                                        font-size: 1.3rem;
                                        font-weight: bold;
                                    }

                                    .react-datepicker__day-names {
                                        width: 100%;
                                        padding: 0.4rem 0.5rem;
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-around;
                                    }
                                }

                                .react-datepicker__week {
                                    width: 100%;
                                    display: flex !important;
                                    flex-direction: row !important;
                                    justify-content: space-between !important;

                                    .react-datepicker__day {
                                        line-height: 2rem;
                                        width: 2rem;
                                        margin: 0.336rem;
                                    }
                                }

                                .react-datepicker__day--keyboard-selected,
                                .react-datepicker__month-text--keyboard-selected,
                                .react-datepicker__quarter-text--keyboard-selected,
                                .react-datepicker__year-text--keyboard-selected,
                                .react-datepicker__day--selected,
                                .react-datepicker__day--in-selecting-range,
                                .react-datepicker__day--in-range,
                                .react-datepicker__month-text--selected,
                                .react-datepicker__month-text--in-selecting-range,
                                .react-datepicker__month-text--in-range,
                                .react-datepicker__quarter-text--selected,
                                .react-datepicker__quarter-text--in-selecting-range,
                                .react-datepicker__quarter-text--in-range,
                                .react-datepicker__year-text--selected,
                                .react-datepicker__year-text--in-selecting-range,
                                .react-datepicker__year-text--in-range {
                                    background-color: $primaryVeryLight !important;
                                    color: $primaryColor !important;
                                }

                            }

                            .react-datepicker__navigation {
                                top: 10px;
                            }

                            .react-datepicker__navigation-icon {
                                &::before {
                                    border-color: $primaryColor;
                                }
                            }

                            .react-datepicker__navigation--previous {
                                left: 10px;

                            }

                            .react-datepicker__navigation--next {
                                right: 10px;
                            }
                        }
                    }

                    .traveling-selection {
                        margin: 15px auto 0px;
                        padding: 1rem;
                        border-top: 1px solid $lightGray;

                        h6 {
                            font-weight: bold;
                            font-size: 1rem;
                        }

                        .icon-n-radio {
                            .material-symbols-outlined {
                                color: $headerSecondary !important;
                                font-size: 1.5rem;
                            }

                            .travelBy-radio-btn {
                                font-size: 0.9rem;

                                &:not(:last-child) {
                                    padding-right: 10px;
                                }
                            }
                        }
                    }
                }
            }

            .planned-trips-data {

                .heading {
                    padding: 5px;
                    margin-bottom: 0.5rem;
                    border-bottom: 1px solid $primaryPaleLight;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h4 {
                        font-size: 1.5rem;
                        color: $secondaryColor;
                        text-align: center;
                    }

                    .travel-next-step {
                        font-size: 1rem;
                        color: $primaryColor;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .trips-data {
                    padding: 5px;
                    height: 55vh;
                    overflow-x: hidden;

                    .bullet-icon-with-data {
                        margin: 8px 0px;
                        display: flex;
                        align-items: flex-start;

                        .material-symbols-outlined {
                            font-size: 1.4rem;
                            margin-right: 5px;
                        }

                        .data-by-date {
                            font-size: 1rem;
                        }
                    }
                }
            }

            .full-client-travel-details {
                .page-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0px 15px 10px 15px;
                    padding: 5px;
                    border-bottom: 1.5px solid $primaryPaleLight;

                    h4 {
                        color: $primaryColor;
                        font-size: 1.2rem;
                        font-weight: bold;
                    }

                    .travel-next-back-step {
                        color: $primaryColor;
                        cursor: pointer;

                        .material-symbols-outlined {
                            font-size: 1.3rem;
                        }

                    }
                }

                .planner-details-n-client-details {
                    padding: 15px 10px;

                    .select-team-client-details {
                        display: flex;
                        flex-direction: column;
                        align-items: first baseline;
                        width: 90%;
                        padding: 0px 10px;

                        .title-n-input-field {
                            // position: relative;
                            margin-bottom: 0.6rem;
                            width: 100%;

                            .title-text {
                                &.planner-name {
                                    padding: 10px;
                                    background-color: $primaryVeryLight;
                                }

                                .head-text {
                                    margin-bottom: 0.2rem;
                                }
                            }

                            .react-select-container-list {
                                margin: 0px !important;

                                .react-select-list__control {
                                    min-height: 36px;
                                }
                            }

                        }
                    }

                    .selcting-modeoftravel-with-icon {
                        margin: 10px;

                        .traveling-selection {
                            // background-color: $primaryPaleLight;
                            box-shadow: $card_box_shadow;
                            padding: 15px 20px;
                            width: 90%;
                            margin: auto;
                            border-radius: 1.5rem;

                            .checkbox-selction {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: flex-start;

                                .travel-checkboxes {
                                    margin: 5px;
                                    padding: 10px;
                                    border-radius: 1rem;
                                    background-color: $primaryVeryLight;
                                    width: 6rem;
                                    height: 6rem;
                                    display: flex;
                                    flex-wrap: wrap;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: space-between;

                                    &:hover {
                                        transform: scale(1.05);
                                        border: 0.15rem dotted $primarySemiLight;
                                    }


                                    .travelOption-checkbox {
                                        position: relative;
                                        margin: auto;

                                    .travel-checkbox-input {
                                            float: right !important;
                                            margin-right: 2em !important;
                                            border-radius: 0.4rem !important;
                                        }

                                        .chekbox-n-text-n-icon {
                                            width: 5rem;
                                            height: 5rem;
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            position: relative;
                                            left: -25px;
                                            top: -0.5rem;


                                            .checkbox-text {
                                                font-size: 0.8rem;
                                                font-weight: bold;
                                                text-align: center;
                                                line-height: normal;
                                                position: relative;
                                                top: -6px;
                                            }

                                            .text-icon {

                                                // padding: 5px;
                                                .material-symbols-outlined {
                                                    font-size: 2.5rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .client-trip-n-planner-details {
                    .title-n-input {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 5px auto;

                        &.remarks-part {
                            align-items: baseline;
                        }

                        .title-text {
                            width: 30%;
                            padding: 3px 5px;

                            h6 {
                                font-size: 0.85rem;
                                font-weight: 500;
                                color: $primaryColor;
                            }
                        }

                        .input-field {
                            width: 70%;
                            padding: 3px 5px;

                            .react-select-container-list {
                                margin: 0px;
                            }
                        }
                    }

                    .traveling-selection {
                        padding: 5px;

                        h6 {
                            font-size: 0.85rem;
                            font-weight: 500;
                            color: $primaryColor;
                        }

                        .checkbox-selction {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                        }
                    }
                }

                .fill-booking-details {
                    .heading {
                        text-align: center;
                        padding: 5px;
                    }

                    .travel-book-by-selections {
                        padding: 20px 25px;
                        height: auto;
                        // overflow-x: hidden;

                        .traveltype-details {
                            width: 100%;
                            margin: 10px auto;
                            padding: 10px;
                            box-shadow: $card_box_shadow;

                            .travel-icon-with-text-n-type {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: flex-start;
                                margin-left: 15px;

                                &:is(.Flight, .Train, .Bus, .Cab, .Hotel) {
                                    padding-bottom: 8px;
                                    border-bottom: 1px solid $lightGray;
                                }

                                &:is(.Advance) {
                                    width: 50%;
                                }

                                .travel-icon {
                                    background-color: $primaryPaleLight;
                                    padding: 6px;
                                    border-radius: 50%;
                                    width: 30px;
                                    height: 30px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-right: 10px;

                                    .material-symbols-outlined {
                                        color: $whiteColor !important;
                                        font-size: 22px;
                                    }
                                }

                                .travel-type {
                                    min-width: 140px;
                                    margin-right: 10px;
                                    padding-right: 10px;
                                    border-right: 1.5px solid $primarySemiLight;

                                    h6 {
                                        font-size: 1.2rem;
                                        font-weight: bold;
                                        color: $primaryColor;
                                    }
                                }

                                .trip-type {
                                    padding: 5px;

                                    .form-check {
                                        margin-bottom: 0px !important;
                                        margin-top: 0.25rem;
                                    }
                                }
                            }

                            .travel-from-to-qty-datetime {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                padding: 10px 5px;

                                .journey-row {
                                    padding: 5px 0px;
                                    border-bottom: 2px dotted $primaryVeryLight;
                                }

                                .input-select-boxes-flex {
                                    // dflex-alignBase-jbetween
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    align-items: baseline;
                                    justify-content: flex-start;
                                    position: relative;

                                    .remove-row {
                                        display: flex;
                                        align-items: center;
                                        padding: 5px;
                                        cursor: pointer;
                                        position: relative;
                                        top: 5px;

                                        .material-symbols-outlined {
                                            font-size: 1.3rem;
                                        }
                                    }
                                }

                                .inputs-by-triptype {
                                    padding: 8px;
                                    position: relative;
                                    // &.return-date {
                                    //     margin: 5px 5px 0px auto;
                                    // }

                                    &.plus-icon {
                                        padding: 4px 10px;
                                        width: max-content;
                                        cursor: pointer;
                                        background-color: $primaryVeryLight;
                                        border-radius: 0.8rem;
                                        margin: 5px 5px 0px auto;

                                        .add-another-row {
                                            display: flex;
                                            align-items: center;

                                            .material-symbols-outlined {
                                                font-size: 1rem;
                                            }

                                            .add-text {
                                                color: $primaryColor;
                                                font-size: 0.9rem;
                                                margin-left: 3px;
                                            }
                                        }

                                        &:hover {
                                            background-color: $primaryPaleLight;
                                        }
                                    }

                                    .react-datepicker {
                                        width: max-content;

                                        .react-datepicker__time-container {
                                            .react-datepicker__time {
                                                .react-datepicker__time-box {
                                                    ul.react-datepicker__time-list {
                                                        li.react-datepicker__time-list-item--selected {
                                                            background-color: $primaryColor !important;
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                    }
                                }
                            }

                            .checkIn-checkOut-qty-rooms {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                padding: 10px 5px;

                                .journey-row {
                                    padding: 8px 0px;
                                    border-bottom: 2px dotted $primaryVeryLight;
                                }

                                .remove-row {
                                    display: flex;
                                    align-items: center;
                                    padding: 5px;
                                    cursor: pointer;
                                    position: relative;

                                    .material-symbols-outlined {
                                        font-size: 1.3rem;
                                    }
                                }

                                .inputs-by-triptype {
                                    margin: 5px;

                                    &.plus-icon {
                                        padding: 4px 10px;
                                        width: max-content;
                                        cursor: pointer;
                                        background-color: $primaryVeryLight;
                                        border-radius: 0.8rem;
                                        margin: 5px 5px 0px auto;

                                        .add-another-row {
                                            display: flex;
                                            align-items: center;

                                            .material-symbols-outlined {
                                                font-size: 1rem;
                                            }

                                            .add-text {
                                                color: $primaryColor;
                                                font-size: 0.9rem;
                                                margin-left: 3px;
                                            }
                                        }

                                        &:hover {
                                            background-color: $primaryPaleLight;
                                        }
                                    }


                                }
                            }
                        }
                    }
                }
            }

            .plan-details-preview {
                .page-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0px 15px 10px 15px;
                    padding: 5px;
                    border-bottom: 1.5px solid $primaryPaleLight;

                    .travel-next-back-step {
                        color: $primaryColor;
                        cursor: pointer;

                        .material-symbols-outlined {
                            font-size: 1.3rem;
                        }

                    }
                }

                .preview-data {
                    .preview-left-part {
                        padding: 0.35rem;

                        .data-by-row {
                            display: flex;
                            align-items: baseline;
                            justify-content: flex-start;

                            .data-label {
                                color: $blackColor;
                                padding: 0.5rem;
                                font-size: 0.8rem;
                                font-weight: 500;
                                min-width: 150px;
                                max-width: 150px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            }

                            .data-value {
                                color: $primaryColor;
                                padding: 0.5rem;
                                font-size: 0.9rem;
                            }
                        }
                    }

                    .preview-right-part {
                        padding: 0.35rem;

                        .booking-overview {
                            .heading {
                                font-size: 1rem;
                                font-weight: 500;
                                color: $primaryColor;
                                text-align: center;
                            }

                            .traveling-details {
                                padding: 0.5rem;
                                margin: 0.5rem;
                                background-color: $primaryVeryLight;

                                .travel-type {
                                    padding: 0.5rem;

                                    .main-head {
                                        color: $primaryColor;
                                        font-weight: bold;
                                        font-size: 0.9rem;
                                        text-transform: uppercase;
                                    }

                                    .sub-head {
                                        font-size: 0.7rem;
                                        text-transform: capitalize;
                                        color: $primaryHeaderColor;
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}