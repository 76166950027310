@import "variables";

.exception-handler-page{
    margin: 50px 30px;
    // height: 100vh;
    .exceptionhandler-table{
        height: 400px;
        width: 100%;
        padding: 12px;
        background-color: $whiteColor;
        .css-1e2bxag-MuiDataGrid-root{
            border: none;
            border-radius: 0px;
        }
        .css-z8fhq1-MuiDataGrid-columnHeaders{
            background-color: $tableHead_backgroundColor;
            font-size: $fz16;
            font-weight: $fwt600;
            text-transform: uppercase;
        }
        .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
            justify-content: center;
        }
        .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell--textLeft, .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
            justify-content: center;
        }
    }
}