@import "variables";
@import "mixins";

.google-maps-activity-model {
    padding: 0px !important;
    .modal-dialog {
        min-width: 100%;
        margin: auto;
        .modal-content{
            height: 100vh;
        }
    }
    .play-pause-back-next-buttons{
        .btn-disabled{
            pointer-events: none !important;
            opacity: 0.4 !important;            
        }
    }
}

