@import "../styles/variables";
@import "../styles/mixins";



// MEDIA QUERY START

// SCREEN SIZE > 1900px START
@media screen and (min-width: 1900px) {
    body {
        font-size: 14px !important;
    }

    .fa-icon-div {
        width: 24px !important;
    }

    .rmsc .dropdown-container {
        height: 42px !important;
    }

    .expanded-nav-bar {
        li {
            padding: 0.65rem;
        }

        .xn-text {
            margin-left: 12px !important;
            font-size: $fz16 !important;
        }
    }

    .side_bar_header_main {
        height: 5rem !important;
    }

    .dashbord-img-box {
        .dashboard-pop-img {
            margin-top: 10% !important;
            margin-left: 5% !important;
            width: 90% !important;
        }
    }

    .card-dashboard {
        .card-body {
            .material-symbols-outlined {
                font-size: 2.5rem !important;
            }

            .card-head {
                font-size: 0.95rem !important;
            }

            .sb-grap-text {
                p {
                    font-size: 0.85rem !important;
                }
            }
        }
    }

    .table-div-dashboard.graph-card {
        .ord-comp-meet-claim-data {
            .title-n-data {
                .data-title {
                    font-size: $fz16 !important;
                }

                .data-value {
                    font-size: $fz20 !important;
                }
            }
        }
    }

    .meeting-table-header {
        .card-head {
            font-size: 1rem !important;
        }
    }

    .sort-div {
        .form-control {
            font-size: 14px !important;

            &.activity-search {
                width: 20rem !important;
            }
        }
    }

    .table-user-image {
        .table-data-user-img {
            width: 65px !important;
            height: 65px !important;
        }

        h6 {
            font-size: 0.95rem !important;
        }
    }

    .user-activity-images {
        .act-icon {
            .activity-icons {
                width: 30px !important;
                height: 30px !important;

                .fa,
                .fas {
                    font-size: 1.25rem !important;
                }
            }

            p {
                font-size: 0.75rem !important;
            }
        }
    }

    .apexcharts-text {
        tspan {
            font-size: 0.75rem !important;
        }
    }

    .apexcharts-legend-text {
        font-size: 0.88rem !important;
    }

    .page-header-text {
        font-size: 0.9rem !important;
    }

    #metricInfo_search_bar {
        font-size: 0.9rem !important;
        padding: 0.65rem 1.5rem 0.65rem 1.5rem !important;
        min-width: 25rem !important;
    }

    .page-filter-section,
    .page-top-Filters {
        .react-select-container-list {
            .react-select-list__control {
                min-height: 35px !important;
                height: 35px !important;
                font-size: $fz14 !important;

            }
        }

        .from-to-date-select {
            .input-group-text {
                height: 35px !important;
                font-size: 14px !important;
            }

            #date-range-picker {
                height: 35px !important;
                font-size: 14px !important;
                min-width: 200px !important;
            }

            .calander-icon {
                right: 8px !important;
                top: 10px !important;
            }
        }
    }

    .filter-row-search-bar {
        #filterRowSearch {
            height: 35px !important;
            font-size: 14px !important;
        }

        .search-icon {
            right: 10px !important;
            top: 9px !important;
            font-size: 15px !important;
        }
    }

    .admin-otp-form {
        .page-heading h6 {
            font-size: 2rem !important;
        }

        .otp-text {
            font-size: 0.9rem !important;
        }

        .admin-form {
            .form-field-div {
                padding: 1.35rem 0 !important;

                input {
                    width: 46px !important;
                    height: 42px !important;
                    margin-right: 1rem !important;
                }
            }

            p {
                font-size: 0.95rem !important;
            }

            .resend-otp {
                font-size: 0.9rem !important;
            }
        }
    }
}


// SCREEN SIZE > 1900px END

@media screen and (min-width: 1600px) {
    .signin-header {
        .signin-button {
            &.mb20 {
                margin-top: 2rem !important;
            }
        }
    }

    .form-group.mt3 {
        margin-top: 1.25rem !important;
    }

    .signup-contry-codes-div {
        .css-13cymwt-control {
            font-size: 0.9em !important;
            padding: 6px 8px !important;

            &:hover,
            :focus,
            :visited {
                font-size: 0.9em !important;
            }
        }

        .css-t3ipsp-control {
            font-size: 0.9em !important;

            &:hover {
                font-size: 0.9em !important;
            }
        }

        .react-select-list__menu {
            font-size: 0.9em !important;
        }
    }

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START
    .signIn-container-gif {
        margin: 5% 0px auto 4%;

        img {
            width: 95% !important;
        }

    }

    .signin-header {
        font-size: 18px !important;

        // &.login-part {
        //     .login-header-part {
        //         margin-top: 0px;
        //     }
        // }

        &.otp-verification-part {
            .otp-header-part {
                margin-bottom: 3rem;
                margin-top: -250px;
            }
        }

        &.forgotpass-part {
            .forgotpass-header-part {
                margin-top: -55px;

                p {
                    font-size: $fz14;
                }
            }
        }

        &.signup-part {
            padding: 5px 0px;

            .recaptcha-part {
                .recaptch-modal-kit {
                    transform: scale(1) !important;
                    margin: 1rem auto !important;
                }
            }
        }
    }

    .icon-with-input {
        &.before {
            .fa {
                left: 3.55% !important;
                font-size: 1.5em !important;
            }
        }

        .pass-hide-show {
            i {
                top: 2% !important;
                font-size: 1.2em !important;
            }
        }
    }

    .forgot-pass-n-get-otp {
        .forget-pasword-div {
            a {
                font-weight: 500;
                font-size: $fz16;
            }
        }

        .sign-in--using-otp-div {
            .otp-signin {
                font-size: $fz16;
                font-weight: 500;
            }
        }
    }


    .signIn-container-right-div {
        .signIn-form-control {
            border-radius: 0.85rem !important;
            font-size: 1.35em !important;
            padding: 0.85rem 1.85rem 0.85rem 3rem !important;
        }
    }

    .signIn-footer {
        font-size: 18px;

        &.mt20 {
            margin-top: 1.5rem !important;
        }

        a {
            font-size: 18px;
        }
    }

    // ATTENDANCE START
    .atten-monthly {

        th:nth-child(2),
        td:nth-child(2) {
            left: 155px;
        }

        th:nth-child(3),
        td:nth-child(3) {
            left: 260px;
        }

        th:nth-child(4),
        td:nth-child(4) {
            left: 375px;
        }
    }

    // ATTENDANCE END

    // TextBox-SelectBox Inputs
    .add-edit-details-container,
    .add-edit-model {
        .form-floating>.form-control,        
        .form-floating>.form-control:not(:placeholder-shown),
        .form-floating:focus-within>.form-control,
        .model-input {
            padding: 8px 15px !important;
            height: 42px !important;
        }

        .float-input>label {
            bottom: 8px !important;
        }

        .searchWrapper {
            min-height: 42px !important;
            padding: 0.45rem 0.8rem !important;
        }

        .model-select {
            .react-select-list__control {
                padding: 5px 14px 5px 10px !important;
                height: 42px !important;

                .react-select-list__indicator {
                    top: 8px !important;
                }
            }
        }

        .meeting-date-picker-with-icon {
            i {
                font-size: 1rem !important;
            }

            .select-add-meeting-model {
                &.meeting-date {
                    font-size: $fz14 !important;
                    height: 42px !important;
                }
            }
        }

        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
            height: 42px !important;
        }
    }

    // TextBox-SelectBox Inputs
}

@media screen and (width > 1355px) and (width < 1599px) {
    .signin-header {
        font-size: 18px !important;
        // .whitle-labeling-logo {
        //     margin-bottom: 6rem !important;
        // }
        // .login-header-part {
        //     margin-top: -4rem !important;
        // }
    }

    .signup-contry-codes-div {
        .css-13cymwt-control {
            font-size: 0.85em !important;

            &:hover,
            :focus,
            :visited {
                font-size: 0.85em !important;
            }
        }

        .css-t3ipsp-control {
            font-size: 0.85em !important;

            &:hover {
                font-size: 0.85em !important;
            }
        }

        .react-select-list__menu {
            font-size: 0.85em !important;
        }
    }
}

// SCREEN SIZE > 1536px START
@media screen and (max-width: 1920px) {

    .company-card-view,
    .sb-subscription-module .setting-list-div,
    .travel-card-col {
        width: 25% !important;
    }
}

@media screen and (max-width: 1536px) {
    .user-card-view {
        width: 25% !important;
    }

    .company-card-view,
    .sb-subscription-module .setting-list-div,
    .travel-card-col {
        width: 33.33% !important;
    }

    .card-head,
    .td-user-location {
        font-size: $fz14 !important;
    }

    // .grap-imag {
    //     width: 100% !important;
    // }

    .grapg-skeleton {
        height: 40px !important;
    }
}

// SCREEN SIZE > 1536px END

// SCREEN SIZE < 1366px START

@media screen and (max-width: 1366px) {

    .company-card-view,
    .sb-subscription-module .setting-list-div {
        width: 33.33% !important;
    }

    .card-dashboard {
        .card-body {
            min-height: 80px !important;
            max-height: 80px !important;
        }
    }

    // .sort-div {
    //     .form-control {
    //         &.activity-search {
    //             line-height: 25px;
    //         }
    //     }
    // }

    .card-imag {
        width: 100% !important;
    }

    .map-element {
        height: 450px - 0 !important;
    }

    .map-conrtainer {
        min-height: 450px !important;
        max-height: 450px !important;
    }

    .user-activity-data-map {
        min-height: auto !important;
        max-height: auto !important;
    }

    .user-activity-data-col {
        min-height: 360px !important;
        max-height: 360px !important;
    }

    .sb-grap-text p,
    .client-text,
    .userlist-thead,
    .td-user-location,
    .card-head,
    .sort-left-dash,
    .selct-drop-by-dash,
    .act-heading,
    .refresh-activity,
    .select-by-dropdown,
    .sb-db-tbody td,
    .table-user-image h6 {
        font-size: 12px !important;
    }

    .user-activity-images p,
    .sb-db-table-th {
        font-size: $fz11 !important;
        overflow-wrap: anywhere;
        word-break: break-all;
    }

    .sb-grap-text {
        line-height: 16px !important;
    }

    .act-num {
        font-size: $fz14 !important;
    }

    .user-list-top-head {
        padding: 8px !important;
    }

    .sch-meeting-head img,
    .direct-visit-head img {
        width: 12px !important;
        height: 12px !important;
    }

    .table-data-user-img {
        width: 40px !important;
        height: 40px !important;

    }

    .activity-icons {
        width: 23px !important;
        height: 23px !important;

        .fa,
        .fas {
            font-size: $fz13;
        }
    }

    .sb-card-title {
        font-size: 14px !important;
    }

    .custom-table-header tr th {
        font-size: $fz11 !important;
    }

    .custom-table-body tr td {
        font-size: $fz12 !important;
    }

    .sb-dashboard-table {

        .css-y8ay40-MuiTableCell-root {
            font-size: 11px;
        }
    }

    .custom-apex {
        height: 225px !important;
        min-height: 200px !important;
    }

    .custom-apex .apexcharts-canvas {
        height: 245px !important;
    }

    .react-select-container-list {
        min-width: 150px !important;
    }

    .grap-imag {
        width: 100% !important;
    }

    .grapg-skeleton {
        height: 30px !important;
    }

    // ATTENDANCE START
    .atten-monthly {

        th:nth-child(2),
        td:nth-child(2) {
            left: 155px !important;
        }

        th:nth-child(3),
        td:nth-child(3) {
            left: 260px !important;
        }

        th:nth-child(4),
        td:nth-child(4) {
            left: 370px !important;
        }
    }

    // ATTENDANCE END

    // REFFERAL PAGE 
    .card-details {
        .user-card-details {
            width: 100% !important;

            .pan-card-header {
                font-size: 1rem !important;
            }

        }
    }

    // REFFERAL PAGE

}

// SCREEN SIZE > 1366px END

@media screen and (min-width: 1439px) {
    .signIn-container-gif {
        .text-sliding-below-img {
            font-size: 14px !important;
        }
    }
}


@media screen and (min-width: 1919px) {
    .signIn-container-gif {
        .text-sliding-below-img {
            font-size: 16px !important;
        }
    }
}


// SCREEN SIZE > 1280px START

@media screen and (max-width: 1281px) {

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START    


    .user-card-view {
        width: 33.33% !important;
    }

    .company-card-view {
        width: 50% !important;
    }

    .form-control {
        &.create-subscription-input {
            margin: 12px auto !important;
            padding: 6px 10px !important;
            font-size: 12px !important;
        }
    }

    .sub-inputs {
        margin: 6px auto !important;

        .form-control {
            padding: 6px 10px !important;
            font-size: 12px !important;
        }

        .form-select {
            padding: 6px 10px !important;
            font-size: 12px !important;

            &.cntry-code {
                width: 35% !important;
            }
        }
    }

    .sb-login-row {
        width: 70% !important;
        padding: 20px !important;

        &.create-subscription {
            padding: 25px 30px !important;
        }
    }

    .col-left {
        padding: 15px 15px !important;
    }

    .sb-login-logo-div {
        padding-bottom: 16px !important;

        &.create-subscription {
            padding-bottom: 5px !important;
        }

        .sb-login-logo {
            width: 60% !important;

            &.metricinfo {
                width: 50% !important;
            }
        }
    }

    .add-user-input,
    .model-input {
        font-size: $fz12 !important;
        line-height: 18px !important;
        height: 30px !important;
    }

    .country-code {
        .react-select-container-list {

            .react-select-list__control {
                height: 30px !important;
            }
        }
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        height: 18px !important;
    }

    .select-add-meeting-model.meeting-date {
        font-size: $fz12 !important;
        height: 30px !important;
    }

    .model-select .react-select-list__control {
        padding: 2px 10px 6px 8px !important;
        height: 30px !important;
    }

    .float-input>label {
        font-size: 12px !important;
        bottom: 8px !important;
    }

    .float-input-visible>label,
    .float-input-visible:focus-within>label,
    .float-input:focus-within>label {
        font-size: 13px !important;
    }

    .float-visible-select>label,
    .float-visible-select:focus-within>label,
    .float-select:focus-within>label {
        font-size: 11px !important;
    }

    .add-user-input,
    .select-add-user-model,
    .model-select {
        font-size: $fz12 !important;
    }

    .country-code .react-select-container-list .react-select-list__control {
        height: 30px !important;
    }

    .login-head-text {
        font-size: 25px !important;

        &.create-subscription {
            font-size: 20px !important;
        }
    }

    p {
        font-size: $fz12 !important;
    }

    .table-div-dashboard .ord-comp-meet-claim-data .title-n-data {
        .data-title {
            font-size: $fz14 !important;
        }

        .data-value {
            font-size: $fz16 !important;
        }
    }



    .sb-signin-text {
        padding-top: 5% !important;
    }


    .login-msg {
        font-size: $fz13 !important;
    }

    .forgot-pass-text {
        font-size: $fz14 !important;
    }

    .btn-login-button {
        padding: 5px 6px !important;
        font-size: $fz14 !important;
    }

    .pass-hide-show {
        font-size: $fz18 !important;
    }

    .devider {
        .v1 {
            border-left: 5px solid $primarySemiLight;
            height: 150px !important;
        }
    }

    .sb-login-bgimg {
        .lgn-moving-img {
            height: 360px !important;
        }
    }

    .sb-grap-text p,
    .client-text,
    .userlist-thead,
    .td-user-location,
    .card-head,
    .sort-left-dash,
    .selct-drop-by-dash,
    .act-heading,
    .refresh-activity,
    .select-by-dropdown,
    .sb-db-tbody td,
    .table-user-image h6 {
        font-size: $fz11 !important;
    }

    .sidebar_content {
        .expanded-nav-bar {
            .nav-link-item {
                min-width: 170px;
            }
        }
    }

    .add-single-bulk {
        .btn-add-dropdown {
            font-size: $fz12;
        }
    }

    tbody .table-row-head-Text {
        font-size: 0.75rem;
    }

    .table-data-users-imgages {
        width: 35px;
        height: 35px;
    }

    .card-data-users-imgages {
        width: 35px;
        height: 35px;
    }

    tbody tr td {
        font-size: $fz12;
    }

    .react-select-container-list .react-select-list__control {
        min-height: 30px !important;
        height: 32px !important;
        font-size: 12px !important;
    }

    .add-single-bulk .btn-sb-cancel {
        font-size: $fz14 !important;
    }

    .main-logo-image {
        max-height: 160px !important;
        min-height: 160px !important;
    }

    .activity-icons .fa,
    .activity-icons .fas {
        font-size: $fz15 !important;
    }


    // ATTENDANCE START
    .atten-monthly {

        th:nth-child(2),
        td:nth-child(2) {
            left: 135px !important;
        }

        th:nth-child(3),
        td:nth-child(3) {
            left: 230px !important;
        }

        th:nth-child(4),
        td:nth-child(4) {
            left: 340px !important;
        }
    }

    // ATTENDANCE END
}

// SCREEN SIZE > 1280px END


// SCREEN SIZE > 1024px START

@media screen and (max-width: 1153px) {

    .signIn-container-gif {
        padding: 5px 10px;

        img {
            width: 100% !important;
        }
    }

    .filter-icon-text {
        font-size: 0.7rem !important;
    }

    .header-filter-btn {
        margin: 0 0.4rem !important;
    }

    .grid-tile-view {
        font-size: 0.7rem !important;
    }

    #metricInfo_search_bar {
        // padding: 0.5rem 1.6rem 0.5rem 1rem !important;
        min-width: 15rem !important;
    }

    .modal-right-fade {
        width: 45% !important;
    }

    .input-div {
        .search-icon {
            right: 4% !important;
            top: 28% !important;
        }
    }

    .sb-subscription-module .setting-list-div,
    .travel-card-col {
        width: 50% !important;
    }

    .sb-login-row {
        width: 80% !important;
        padding: 20px !important;

        &.create-subscription {
            padding: 25px 30px !important;
        }
    }

    .travelize_logo {
        img {
            padding: 0.5rem !important;
            width: 90% !important;
        }
    }

    #header {
        .pro-sidebar {
            .downloadApp-part {
                .download-app {
                    height: 8.5vh !important;
                    padding: 0.4rem !important;

                    .material-symbols-outlined {
                        font-size: 1.4rem !important;
                    }

                    .text {
                        font-size: 0.85rem !important;
                    }
                }
            }
        }
    }

    .sidebar_content {
        .expanded-nav-bar {
            .nav-link-item {
                min-width: 150px !important;
            }

            li {
                padding: 0.35rem !important;
            }

            .xn-text {
                margin-left: 6px !important;
                font-size: $fz12 !important;
            }
        }
    }

    .sidebar-item {
        &.open {
            .sidebar-content {
                .nav-link-item .xn-text {
                    font-size: $fz12 !important;
                }

                .nav-link-item {
                    padding: 0.4rem 0.5rem 0.4rem 1.4rem !important;
                }
            }
        }
    }

    .sidebar-title {
        .bi-chevron-right.toggle-btn {
            margin-right: 12px !important;
            margin-top: 5px !important;
        }
    }

    .back-btn {
        margin: 0 0.5rem 0 0.35rem !important;
    }

    /// DASHBOARD START
    .claim-card,
    .attend-card,
    .company-card,
    .meeting-card {
        .card-head {
            &.desk-view {
                display: none !important;
            }

            &.tab-view {
                display: block !important;
            }
        }

        .desk-view {
            display: none !important;
        }

        .tab-view {
            display: block !important;

            .sb-grap-text {
                line-height: 18px !important;

                .fz14 {
                    font-size: 12px !important;
                }
            }
        }
    }

    .dashbord-img-box {
        .carousel-div {
            width: 100% !important;
        }
    }

    /// DASHBOARD END

    .page-header-text {
        font-size: 12px !important;
    }

    .sb-grap-text p,
    .client-text,
    .userlist-thead,
    .td-user-location p,
    .card-head,
    .sort-left-dash,
    .selct-drop-by-dash,
    .act-heading,
    .refresh-activity,
    .select-by-dropdown,
    .sb-db-tbody td,
    .activity-page-link p,
    .table-user-image h6 {
        font-size: $fz10 !important;
    }

    tbody .table-row-head-Text {
        font-size: 0.75rem !important;
    }


    .grap-box {
        width: 6px !important;
        height: 6px !important;
    }

    .sb-grap-text {
        line-height: 14px !important;
    }

    .meeting-card,
    .attend-card {
        padding: 5px !important;
        margin: 0px !important;
    }

    .card-dashboard,
    .sb-data-col {
        padding: 7px 7px !important;
    }

    .card-dashboard .card-body {
        min-height: 65px !important;
        max-height: 65px !important;
    }

    .table-div-dashboard {
        .ord-comp-meet-claim-data {
            .title-n-data {
                min-height: 68px !important;
                max-height: 68px !important;

                .data-title {
                    font-size: 11px !important;
                }

                .data-value {
                    font-size: 12px !important;

                    i {
                        font-size: 11px !important;
                    }
                }
            }
        }
    }


    .user-activity-data-col {
        min-height: 340px !important;
        max-height: 340px !important;
    }

    .grapg-skeleton {
        height: 30px !important;
    }

    .sort-div {
        .form-control {
            &.activity-search {
                width: 11.5rem !important;
                line-height: 1.5rem !important;
            }
        }
    }

    .meeting-table-header {
        padding: 4px 5px 4px 5px !important;
    }

    .table-div-dashboard {
        padding: 10px !important;
    }

    .table-div-dashboard.third-row-div {
        height: 300px !important;
    }

    .user-activity-data-col .user-activity-rowdata {
        min-height: 550px !important;
        max-height: 650px !important;
    }

    .add-user-main-logo-image {
        max-height: 180px !important;
        min-height: 180px !important;

        .add-user-images {
            height: auto !important;
        }
    }

    .enable-status {
        h5 {
            font-size: 0.85rem !important;
        }
    }

    .template-download {
        font-size: $fz12 !important;
    }

    // ATTENDANCE  START
    .atten-monthly {

        th:nth-child(2),
        td:nth-child(2) {
            left: 135px !important;
        }

        th:nth-child(3),
        td:nth-child(3) {
            left: 230px !important;
        }

        th:nth-child(4),
        td:nth-child(4) {
            left: 325px !important;
        }
    }

    // ATTENDANCE  END

    // PRODUCTS
    .add-edit-product {
        width: 90% !important;
    }

    // PRODUCTS

    // ROUTES
    .add-route-page {
        width: 95% !important;
    }

    // ROUTES
}

@media screen and (max-width: 1279px) {
    .assign-modules-scrollbar {
        font-size: 13px !important;
    }
}


@media screen and (max-width: 1153px) {
    .modal-right-fade {
        width: 45% !important;
    }
}

@media screen and (min-width: 1151px) and (max-width:1278) {
    .signIn-container-gif img {
        width: 94% !important;
    }
}




@media screen and (max-width: 998px) {
    .user-card-view {
        width: 50% !important;
    }
}

// SCREEN SIZE > 1024px END

// SCREEN SIZE > 767px START (TAB)

@media screen and (min-width: 319px) and (max-width : 769px) {

    body {
        font-size: 12px !important;
    }

    .page-wrapper {
        margin: 4.5rem 0.1rem 2.8rem 0rem !important;
    }

    .dis-none {
        display: none;
    }

    .signin-header {
        font-size: 13px !important;

        &.login-part {
            margin: auto !important;
            width: 60% !important;

            .signin-box {
                .whitle-labeling-logo {
                    margin-bottom: 0rem !important;

                    img {
                        width: 30% !important;
                        height: 30% !important;
                    }
                }
            }
        }

        &.forgotpass-part {
            margin: auto !important;

            .forgotpass-form-data {
                margin-bottom: 2.5rem !important;
            }

            .signin-button {
                &.mt5 {
                    margin-bottom: 1rem !important;
                }
            }
        }

        &.signup-part {
            margin: 2rem auto auto auto !important;
        }
    }

    .sigin-page-body {
        position: relative;

        .dflex-flow-row {
            display: inline-flex !important;
            flex-direction: column-reverse !important;

            &.dflex-center {
                justify-content: center !important;
            }

            .devider {
                display: none !important;
            }
        }
    }

    .forgot-pass-text {
        font-size: 11px !important;
    }

    .signIn-container-right-div {
        height: 50vh !important;
        padding: 20px !important;

        .metricinfo-logo {
            justify-content: space-between !important;

            .signIn-logo-small-device {
                display: block !important;
                width: 20% !important;
            }

            .sb-trv-logo {
                width: 10% !important;
            }
        }
    }


    .signIn-container-left-div {
        height: 50vh !important;

        .signIn-container-logo {
            display: none !important;
        }

        .signIn-container-gif {
            margin: auto !important;
        }
    }

    .login-header-part {
        margin-top: 0 !important;
        margin-bottom: 0.5rem !important;
    }

    .form-control.login-email,
    .form-control.login-password {
        margin: 15px auto !important;
        padding: 6px 10px !important;
    }

    .page-wrapper .main-body {
        width: calc(100% - 5px) !important;
        min-height: 100vh !important;
        overflow: hidden !important;
    }

    .navbar-toggler {
        &:focus {
            box-shadow: none;
        }
    }

    // CREATE SUBSCRIPTION
    .recaptcha-part {
        .original-recaptcha {
            position: relative;
            right: 30px;
        }
    }

    // SIDEBAR & TOPBAR
    #header {
        .pro-sidebar {
            &.expanded-bar {
                display: none !important;
            }
        }
    }

    .header-item .dropdown-menu.show {
        transform: translate(-10px, 0px) !important;
    }

    .topbar {
        height: 3.9rem !important;

        #navbar-custom {
            display: none !important;
        }

        li {
            list-style: none !important;
        }

        .hamburger-navbar {
            display: block;
            position: relative;
            top: -8px;
            left: 5px;

            .webapp-logo {
                width: 20% !important;

                .metricinfoapp_logo {
                    .mi-logo {
                        width: 80% !important;
                        height: 100% !important;
                    }
                }
            }
        }

        .show-menu {
            display: block;
            position: relative;
            transition: all 0.5s ease-in-out;

            .burger-menu-bg {
                position: absolute;
                top: -15px;
                left: 0px;
                right: 0px;
                width: 100%;
                opacity: 1;
                background-color: $whiteColor;
                box-shadow: $topBarShadow !important;
                transition: all 0.5s ease-in-out;
                border-radius: 0 0 30px 30px;
                padding: 0.25rem;

                .burger-menu-items {
                    position: relative;
                    height: 400px;
                    overflow-x: hidden;
                    border-radius: 0 0 30px 30px;
                    margin: 0.5rem 0.65rem;

                    .expanded-nav-bar {
                        .nav-link-item {
                            display: flex;
                            align-items: center;
                            min-width: auto !important;
                            padding: 5px;

                            .xn-text {
                                font-size: 15px !important;
                                padding-left: 10px;
                            }

                            .sidebar-title {
                                min-width: 240px;
                                margin: 5px auto;
                                color: #5D6064;

                                .xn-text {
                                    font-size: 15px !important;
                                    padding-left: 10px !important;
                                }

                                .toggle-btn {
                                    margin-left: auto;
                                    margin-right: 10px;
                                }
                            }
                        }

                        .sidebar-item.plain.active {
                            &::before {
                                display: none;
                            }
                        }

                        .nav-link-item.active,
                        .sidebar-item.plain.active {
                            &::before {
                                display: none;
                            }
                        }

                        .sidebar-item.open {
                            .sidebar-content {
                                &.sub-active {
                                    .nav-link-item {
                                        .bi-check-square-fill {
                                            color: $primaryColor;
                                        }
                                    }
                                }

                                .nav-link-item {
                                    .nav-item-icon-hr-module {
                                        width: 18px !important;
                                    }

                                    .fa-icon-div {
                                        font-size: 20px !important;
                                    }

                                    .xn-text {
                                        font-size: 14px !important;
                                    }
                                }
                            }
                        }

                        .sidebar-item {
                            margin: 0px auto;

                            &.download-app-link {
                                width: 300px !important;
                                margin: 5px auto !important;
                                padding-bottom: 10px;
                            }

                            .tab-menu-items {
                                width: 250px;
                                margin: 5px auto;
                                color: #5D6064;
                            }

                            .sub-menu-items {
                                width: 250px;
                                margin: 5px auto;
                                color: #5D6064;
                            }

                            .sidebar-content {
                                .nav-link-item {
                                    font-size: 13px !important;
                                    padding-left: 25px !important;
                                    color: #5D6064 !important;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .Qr-modal {
        .modal-dialog {
            .modal-content {
                padding: 5px !important;
            }
        }
    }

    .minWid180 {
        min-width: 180px !important;
    }

    // DASHBOARD 

    .sb-card-row {
        flex-direction: column !important;

        .card-dashboard {
            width: 100% !important;

            .card-body {
                min-height: 85px !important;
                max-height: 85px !important;

                .material-symbols-outlined {
                    font-size: 2.5rem !important;
                }

                .act-body {
                    .card-head {
                        font-size: 0.95rem !important;
                    }
                }
            }
        }
    }

    .material-symbols-outlined {
        font-size: 1.5rem !important;
    }

    .no-activity-found {
        padding: 0.25rem !important;

        img {
            width: 80% !important;
        }
    }

    .claim-card,
    .attend-card,
    .company-card,
    .meeting-card {
        .card-head {
            &.desk-view {
                display: none !important;
            }

            &.tab-view {
                display: block !important;

            }
        }

        .desk-view {
            display: none !important;
        }

        .tab-view {
            display: block !important;

            .sb-grap-text {
                line-height: 18px !important;

                .fz14 {
                    font-size: 14px !important;
                }
            }
        }
    }


    .user-activity-data-col {
        .user-activity-rowdata {
            min-height: 630px !important;
            max-height: 630px !important;
            overflow-x: hidden !important;
        }
    }

    .meeting-custom-graph {
        .apexcharts-legend {
            inset: auto 0px 20px 5px !important;
        }
    }

    .sort-div {
        .form-control {
            &.activity-search {
                width: 14rem !important;
            }
        }
    }

    .dashbord-img-box {
        .carousel-div {
            width: 50% !important;

            .first-popup-div {
                min-width: 80px !important;
            }
        }
    }

    .meeting-table-header {
        .card-head {
            font-size: 0.85rem !important;
        }

        .meetings-sort {
            &.select-by-dropdown {
                font-size: 0.75rem !important;
            }
        }
    }

    .table-div-dashboard {
        .ord-comp-meet-claim-data {
            .title-n-data {

                .data-title,
                .data-value {
                    font-size: 0.85rem !important;
                }
            }
        }
    }

    .active-user-image {
        .table-data-user-img {
            width: 60px !important;
            height: 60px !important;
        }
    }

    .td-user-location {
        p {
            font-size: 0.85rem !important;
        }
    }

    .user-activity-data-col {
        min-height: 340px !important;
        max-height: auto !important;
        overflow: auto !important
    }


    // COMMON STYLE

    .upld-n-templt-downld {
        margin: auto !important;
    }

    #metricInfo_search_bar {
        min-width: 20rem !important;
    }

    .complaint-issue {
        width: 100% !important;
    }

    // USER PAGE
    .react-select-container-list {
        margin: auto !important;
    }

    .filters-row {
        display: block !important;
    }

    .add-user-main-logo-image {
        margin: 0.85rem auto !important;

        .add-user-images {
            height: auto !important;
        }
    }


    // MEETINGS PAGE
    .meetings-scrollbar {
        height: calc(100vh - 118px) !important;
    }

    // PRODUCTS PAGE
    .add-edit-product-model .modal-dialog {
        max-width: 70% !important;

        .col-md-4 {
            width: 100% !important;
        }

        .col-md-8 {
            width: 100% !important;
        }
    }

    .main-product-image {
        width: 50% !important;
        max-height: 100% !important;
        min-height: auto !important;
        margin: auto !important;

        .product-images {
            object-fit: contain !important;
        }
    }

    .product-box-div {
        min-height: 200px !important;
        max-height: 200px !important;
    }

    // CONNECTOR PAGE
    // Referral-Users

    // ORDERS PAGE


    // CLAIMS PAGE

    .view-meet-details-model,
    .meeter-reading-model {
        .modal-dialog {
            max-width: 650px !important;

        }
    }


    // SETTINGS PAGE

    .device-info-part {
        img {
            position: relative !important;
            width: 55% !important;
        }

        .device-info-table {
            width: 45% !important;
        }
    }

    // PROFILE PAGE
    .profile-page.profile-img {
        width: 110px !important;
        height: 110px !important;
    }

    // ACCOUNT SETTINGS   

    .claim-two-aprroval h6,
    .meeting-range-head h6,
    .client-mapping-setting-title h6 {
        width: 100% !important;
    }


    .sb-setting-card {
        .setting-card {
            display: flex !important;

            .setting-img-card {
                margin: 5px auto !important;
            }
        }

        .setting-card-body {
            text-align: left !important;
        }
    }

    .page-content-center {
        .admin-otp-form {
            padding: 20px 18px !important;
        }
    }

    // TRACK CLIENTS PAGE
    .track-client-page-google-map {
        .leaflet-container {
            height: 50vh !important;
        }
    }

    .profile-right-part {
        width: 100% !important;
    }



    .super-admin-page {
        .admin-login-img {
            border-right: none !important;

            img {
                width: 100% !important;
            }
        }

        .dflex {
            display: block !important;
        }
    }

    .super-admin-controls {
        .superadmin-links {
            .single-card {
                .card-heading {
                    width: auto !important;
                    text-wrap: balance !important;
                }
            }
        }
    }

    .card-row-nowrap-gap {
        flex-wrap: wrap !important;
    }
}

@media screen and (max-width: 426px) {
    .dark-light-theme-switch {
        display: none !important;
        padding: 0 !important;
    }

    .mobile-p-m{
        padding-left: 0 !important;
        margin-top: 1rem !important;
    }
    .dashbord-img-box{
        .dashboard-pop-img{
            margin-top: 5% !important;
        }
    } 

    #metricInfo_search_bar {
        min-width: 15rem !important
    }
       
    .dnone-mobile-view {
        display: none !important;
    }

    .add-edit-details-container {
        .add-edit-menu-bar {
            display: none !important;
        }

        .add-edit-details-form-header {
            height: 10vh !important;
        }

        .addedit-form-body {
            .add-edit-form-part {
                width: auto !important;
            }
        }
    }

    .more-settings-part {
        .pr-border {
            border-right: none !important;
        }
    }

    .setting-page-header{
        .input-div{
            width: 62% !important;
        }
    }
    .super-admin-page{
        .super-admin-controls{
            .superadmin-links{
                .single-card{
                    max-width: 100% !important;
                    min-width: 100% !important;
                }
            } 
        } 
    } 
}

@media screen and (min-width: 601px) and (max-width:769px) {

    .sb-subscription-module .setting-list-div,
    .travel-card-col,
    .company-card-view,
    .user-card-view {
        width: 50% !important;
    }


}

@media screen and (min-width : 514px) and (max-width : 768px) {
    .modal-backdrop {
        .modal-right-fade {
            width: 70% !important;
        }
    }
}

// SCREEN SIZE > 767px END (TAB)

@media screen and (min-width: 515px) and (max-width : 769px) {
    .signIn-container-gif {
        img {
            width: 62% !important;
        }
    }
}

@media screen and (min-width: 319px) and (max-width : 514px) {
    .signIn-container-gif {
        img {
            width: 76% !important;
        }
    }

    .modal-backdrop {
        .modal-right-fade {
            width: 90% !important;
        }
    }
}


@media screen and (min-width: 320px) and (max-width:600px) {

    .sb-subscription-module .setting-list-div,
    .travel-card-col,
    .company-card-view,
    .user-card-view {
        width: 100% !important;
    }
}

@media screen and (width > 997px) and (width < 1151px) {
    .device-info-table {
        font-size: 12px !important;

        .device-details {
            top: 6% !important;
        }

        .modal-update {
            margin-top: 5px !important;
            padding: 6px !important;
        }

        .device-name {
            padding: 5px !important;
        }

        .device-info-table-data {

            th,
            td {
                font-size: 0.75em !important;
                padding: 2px 5px !important;
            }
        }
    }
}

@media screen and (width > 1151px) and (width < 1195px) {
    .device-info-table {
        font-size: 13px !important;

        .device-details {
            top: 6% !important;
        }

        .modal-update {
            margin-top: 5px !important;
            padding: 6px !important;
        }

        .device-name {
            padding: 5px !important;
        }

        .device-info-table-data {

            th,
            td {
                font-size: 0.8em !important;
                padding: 3px 5px !important;
            }
        }
    }
}

@media screen and (width > 1195px) and (width < 1279px) {
    .device-info-table {
        font-size: 13px !important;

        .device-details {
            top: 7% !important;
        }


        .device-info-table-data {

            th,
            td {
                font-size: 0.8em !important;
                padding: 3px 5px !important;
            }
        }
    }
}

@media screen and (width > 1279px) and (width < 1366px) {
    .device-info-table {
        font-size: 14px !important;

        .device-details {
            top: 8% !important;
        }


        .device-info-table-data {

            th,
            td {
                font-size: 0.85em !important;
                padding: 2px 5px !important;
            }
        }
    }
}


@media screen and (min-width : 1023px) and (max-width: 1035px) {

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START
    .signIn-container-gif {
        padding: 5px 10px;

        img {
            width: 100% !important;
        }
    }

    .signin-header {
        &.login-part {
            width: 85%;
        }

        &.otp-verification-part {
            .otp-header-part {
                margin-bottom: 2rem !important;
                margin-top: -100px !important;
            }
        }

        &.forgotpass-part {
            .forgotpass-header-part {
                margin-top: -10px !important;
                margin-bottom: 2rem !important;
            }
        }

        &.signup-part {
            width: 85% !important;
        }
    }

    .form-group {
        &.mb20 {
            margin-bottom: 20px !important;
        }
    }

    .sign-in--using-otp-div {
        margin-right: 0px !important;

        .otp-signin {
            font-size: 15px !important;
        }
    }

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START
}




@media screen and (min-width : 1439px) {

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START   

    .signin-header {
        &.login-part {
            .login-header-part {
                position: relative;
            }
        }

        &.otp-verification-part {
            .otp-header-part {
                margin-bottom: 3rem !important;
                margin-top: -220px !important;
            }
        }

        &.signup-part {
            padding: 5px 0px !important;
        }

        &.forgotpass-part {
            .forgotpass-header-part {
                margin-top: -55px !important;
            }
        }
    }

    .form-group {
        &.mb20 {
            margin-bottom: 25px !important;
        }
    }

    .signin-button {
        &.mt5 {
            margin-bottom: 9rem !important;
        }
    }

    // NEW SIGNIN-FORGOT-SIGNUP PAGE START
    .device-info-part {

        img {
            width: 92% !important;
        }
    }

    .device-info-table {
        width: 76% !important;

        .device-details {
            top: 8% !important;
        }
    }

}



@media screen and (min-width : 1900px) and (max-width: 2559px) {
    .signIn-container-gif {
        img {
            width: 82% !important;
        }
    }

    .signin-header {
        &.login-part {
            width: 70% !important;
        }

        &.otp-verification-part {
            width: 70% !important;

            .otp-header-part {
                margin-bottom: 5rem !important;
                margin-top: -260px !important;
            }
        }

        &.forgotpass-part {
            .forgotpass-header-part {
                margin-top: -175px !important;

                p {
                    font-size: $fz16 !important;
                }
            }

            .back-to-signin {
                a {
                    font-size: $fz16 !important;
                }
            }
        }

        &.signup-part {
            .recaptcha-part {
                .recaptch-modal-kit {
                    transform: scale(1) !important;
                    margin: 15px auto !important;
                }
            }
        }
    }

    .signIn-container-right-div {
        .signIn-form-control {
            border-radius: 1rem !important;
            font-size: 1.4em !important;
            padding: 0.8rem 1.85rem 0.8rem 3rem !important;
        }
    }

    .icon-with-select {
        &.sbefore {
            .fa {
                font-size: 18px !important;
                position: absolute !important;
                top: 16px !important;
                left: 15px !important;
            }
        }
    }

    .signup-contry-codes-div {
        .css-13cymwt-control {
            font-size: $fz16 !important;
            border-radius: 15px !important;
            padding: 7px !important;

            &:hover,
            :focus,
            :visited {
                border-radius: 15px !important;
                padding: 7px !important;
            }
        }

        .css-t3ipsp-control {
            border-radius: 15px !important;
            padding: 7px !important;
            font-size: $fz16 !important;

            &:hover {
                border-radius: 15px !important;
                padding: 7px !important;
                font-size: $fz16 !important;
            }
        }
    }
}