@import "variables";
@import "mixins";

.kpa-add-container {
    position: relative;
    overflow: auto;
    max-height: 300px;
    margin-top: 10px;

    .kpa-add-inner-container {
        display: grid;
        grid-template-columns: 230px 230px;
        margin-bottom: 10px;
        padding-top: 7px;
    }
}

.user-rating {
    i {
        font-size: 20px;
        color: $header_Gradient !important;
    }

    .fa-star,
    .fa-star-o {
        color: $secondaryColor;
    }
}

.view-kpa-datails span {
    display: grid;
    grid-template-columns: 150px 20px 150px;
    color: grey;
    text-shadow: white 1px 1px 1px;
    font-size: 13px;
    width: 100%;
    justify-content: flex-start;
}