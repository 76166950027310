@import "variables";
@import "mixins";

.forms-n-data-table {
    .customforms-n-names {
        margin-top: 5px;

        .customforms-table {
            padding-bottom: 0.65rem;
            .routes-table-data {
                tbody {
                    tr {
                        td {
                            padding: 10px 6px !important;
                        }
                    }
                }
            }
        }
    }
}

.view-formdata-model {
    .modal-dialog {
        max-width: 60% !important;
        margin: auto;
    }

    .view-forms-data {
        margin: 10px 20px 15px 20px;
        // border: 2px solid $primaryPaleLight;
        border-radius: 10px;
        // padding: 3px;
        .form-data-scroll {
            padding: 15px 20px;
            max-height: 500px;
            min-height: 500px;
            overflow-x: hidden;
            
            .form-heading {
                padding: 5px 5px 20px 5px;
                color: $primaryColor;
                margin-bottom: 5px;
                h5{
                    text-decoration: underline;
                    text-transform: uppercase;
                    
                }
            }
        }
    }
}